import React from 'react'
import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogContentText from '@material-ui/core/DialogContentText';
import DialogTitle from '@material-ui/core/DialogTitle';
import Slide from '@material-ui/core/Slide';
import TextField from '@material-ui/core/TextField';
import AssignmentService from '../../services/AssignmentService';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
  });

function MarkAsComplteComponent({dialogStatus, userId, taskId, setLoading, setError}) {

    const [open, setOpen] = React.useState(dialogStatus);
    const [answer, setAnswer] = React.useState('');
    const [btnDisable, setBtnDisable] = React.useState(true)

    React.useEffect(() => {
        if (answer === 'COMPLETE') {
            setBtnDisable(false)
        } else {
            setBtnDisable(true)
        }
    }, [answer])


    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleSubmit = () => {
        setLoading(true);

        if(answer === 'COMPLETE') {
            setOpen(false);
            AssignmentService.assignemntMarkAsComplete(userId, taskId)
            .then(() => {
                window.location.reload();
                setLoading(false);
            }).catch(function (error) {
                let message = 'Mark As Complete Error !'
                if (error.response) {
             
                  message =  error.response.data.message ?? 'Mark As Complete Error !'
                } 
        
                setError({
                  type: 'error',
                  message: message
                  })
               
              });
        }
       
    }

    

    
    return (
        <div>
            <Button variant="outlined" color="secondary" style={{ marginLeft: '2em' }} sx={{ fontSize: { md: '2em', lg: '1em' }, padding: { md: '1em', lg: '1.25em' } }}
            onClick={handleClickOpen}>
                Mark as Complete
            </Button>
            <Dialog
            open={open}
            TransitionComponent={Transition}
            keepMounted
            onClose={handleClose}
            aria-describedby="alert-dialog-slide-description"
            maxWidth={'lg'}
            >
            <DialogTitle></DialogTitle>
            <DialogContent>
                <DialogContentText id="alert-dialog-slide-description" style={{lineHeight: '1.75'}}>
                    <h1 style={{color: 'red'}}>{"ATTENTION!!!"}</h1>
                    <p style={{color: 'red', fontSize: '1rem'}}>
                        Once you mark this assignment as 'Completed', then the system will lock your assignment and you no longer will be able to edit anything you entered. You will be able to view your assignment data, but you will not be able to edit.</p>
                    <p style={{color: 'red', fontSize: '1rem'}}>
                        There is no way to reverse this lock. No matter how much you request us, we simply cannot do it. Because your customised content will be rendered based on this data. Therefore, make sure that you are 100% satisfied with the answers you entered for this assignment before you mark it as ‘Completed’.
                    </p>
                    <p style={{color: 'red', fontSize: '1rem'}}>
                        Once you are ready, please type <b>'COMPLETE'</b> in capital letters as your answer and press <b>'Mark as Complete'</b> button.
                    </p>
                    <br/>
                    <h1 style={{color: 'red'}}>{"ඔබගේ අවධානය පිණිසයි!!!"}</h1>
                    <p style={{color: 'red', fontSize: '1rem'}}>
                    ඔබ මෙම පැවරුම <b>'Mark as Completed'</b> ලෙස සලකුණු කල විට එම පැවරුමට අගුළු වැටේ. එනම් ඔබට පැවරුමට ඇතුලත් කල දත්ත නැරඹිය හැකි නමුදු එම දත්ත වෙනස් කිරීම කල නොහැකි වේ.
                    </p>
                    <p style={{color: 'red', fontSize: '1rem'}}>
                    මෙම අගුළ කිසිම හේතුවක් මත නැවත මුදාහැරිය නොහැක. ඔබ අපගෙන් කෙතරම් ඉල්ලීම් කලද අගුළ අපටද විවෘත කල නොහැක. මන්ද ඔබට අභිරුචිකරණය කරන ලද කොටස් ලැබෙන්නේ මෙම දත්ත ආශ්‍රයෙන් නිසාය.
                    </p>

                    <p style={{color: 'red', fontSize: '1rem'}}>
                    එම නිසා <b>'Mark as Completed'</b> කිරීමට පෙර පැවරුමට ඔබ ඇතුලත් කොට ඇති දත්ත පිළිබඳව ඔබ 100% තෘප්තිමත් බව සැක හැර දැනගන්න.
                    එසේ ඔබ සූදානම් වූ පසු පහතින් කැපිටල් අකුරෙන්  COMPLETE ලෙස ටයිප් කොට, 'Mark as Completed ' බොත්තම ඔබන්න.
                    </p>
                </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="answer"
                        label="Your answer"
                        type="text"
                        value={answer}
                        fullWidth
                        variant="standard"
                        onChange={(e) => setAnswer(e.target.value)}
                    />
            </DialogContent>
            <DialogActions>
                <Button variant="outlined" color="primary" onClick={handleClose}>Cancel</Button>
                    <Button variant="outlined" disabled={btnDisable} color="secondary" onClick={handleSubmit}>Mark as Complete</Button>
            </DialogActions>
            </Dialog>
        </div>
    )
}

export default MarkAsComplteComponent