import React from 'react';
import { Route, Switch, useLocation} from 'react-router-dom';

// project imports
import MainLayout from './../layout/MainLayout';
import AuthGuard from './../utils/route-guard/AuthGuard';
import DashboardPage from '../views/pages/dashboard';
import AssignmentList from '../views/pages/assignment';
import Assignment from '../views/pages/assignment/assignment';
import CustomizedContentPage from '../views/pages/customization/customizedContent';
import CustomizedLessionPage from '../views/pages/customization/CustomizedLession';



//-----------------------|| MAIN ROUTING ||-----------------------//

const MainRoutes = () => {
    const location = useLocation();

    return (
        <Route path={['/pages']}>
            <MainLayout>
                <Switch location={location} key={location.pathname}>
                    <AuthGuard>
                        <Route exact path="/pages/dashboard" component={DashboardPage}  forceRefresh={true}/>
                        <Route exact path="/pages/customizedContent" component={CustomizedContentPage} forceRefresh={true} />
                        <Route exact path="/pages/customizedLesson/:courseId" component={CustomizedLessionPage} forceRefresh={true} />
                        <Route exact path="/pages/:courseId/assignment" component={AssignmentList} />
                        <Route exact path="/pages/assignment/:id" component={Assignment} />
                    </AuthGuard>
                </Switch>
            </MainLayout>
        </Route>
    );
};

export default MainRoutes;
