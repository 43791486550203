import React, { useState, useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import SelectChipComponent from './../SelectChipComponent';
import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import UpdateIcon from '@material-ui/icons/Update';
import Divider from '@material-ui/core/Divider';
import SelectComponent from './../SelectComponent';
import SelectChipFreeSoloComponent from './../SelectChipFreeSoloComponent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import EditIcon from '@material-ui/icons/Edit';
import IconButton from '@material-ui/core/IconButton';
import { AssignmentContext } from '../../contexts/AssignmentContext';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';

import { validate } from '../../utils/assignmentInputValidators';
import { DialogContentText, Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1em 0',
  },
  btnSuccess: {
    color: 'white'
  },
  formControl: {
    margin: '1em 0'
  },
  button: {
    margin: '0 2em'
  },
  footer: {
    width: '95%',
    margin: '0 auto',
  },
  error: {
    color: 'red',
    marginBottom: '0px',
    marginTop: '5px',
    marginLeft: '5px'
  },
  resize: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5em'
    }
  }
}));

const EditComponent = ({ data, editFunc, dependencies }) => {

  const classes = useStyles();
  const [open, setOpen] = useState(false);
  const assignmentContext = useContext(AssignmentContext);
  const [formData, changeFormData] = React.useState(data)
  const [errors, setErrors] = React.useState({});
 const [checked, setChecked] = React.useState(formData?.aligned);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let formD = { ...formData };
    formD[event.target.name] = !checked;
    validateState(formD);
    changeFormData(formD);
  };

  const handleClickOpen = () => {
    setOpen(true);
    setErrors({})
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = validate(assignmentContext.assignment, formData, assignmentContext.results);
    if (Object.keys(validation).length === 0) {
      editFunc(formData);
      handleClose();
    } else {
      //setting the errors returned from validation
      setErrors(validation);
      console.log('errors....', errors)
    }

  }
  // let elementName = '';
  const handleStateUpdate = (event) => {
    let formD = { ...formData };
    formD[event.target.name] = event.target.value;
    
    // remove errors of specific field
    delete errors[event.target.name]
    setErrors(errors)
    validateState(formD);
    changeFormData(formD);
  }

  const validateState = (formD) => {

    if('Dream Validation' === assignmentContext?.assignment?.title)
    {
      let requiredQualities = formD?.question ?? [];
      let otherRequiredQualities = formD?.qualities ?? [];
      let benificialQualities = formD?.benificial_qualities ?? [];
      let otherbBenificialQualities = formD?.other_benificial_qualities ?? [];

      let allRequiredQualities = (requiredQualities?.concat(otherRequiredQualities))?.map(value => value.id);
      let allBenificialQualities = benificialQualities?.concat(otherbBenificialQualities)?.map(value => value.id);;
      let unionQualities = allRequiredQualities.filter(x => allBenificialQualities.includes(x));
      let percentage = (unionQualities?.length / allRequiredQualities?.length) * 100 ?? 0;
      let color = percentage > 60 ? 'error' : 'default';
      let text =  percentage > 60 ? 'Awesome' : '';

      if (true === formD?.aligned) {
        color = 'success';
        text = 'aligned';
      }
      
      formD['state'] = {percentage: percentage , color: color, text: text};
    }

    if('Flow state' === assignmentContext?.assignment?.title)
    {
      let color ='default';
      let text =  '';
      let percentage = 0;
      if (true === formD?.aligned) {
        color = 'success';
        text = 'aligned';
      }
      
      formD['state'] = {percentage: percentage , color: color, text: text};
    }
  }


  return <>
    <IconButton aria-label="edits" onClick={handleClickOpen} style={{display: true === assignmentContext?.taskCompleted ? 'none': 'flex'}}>
      <EditIcon sx={{ fontSize: { md: '2em', lg: '1.25em' } }} />
    </IconButton>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
      <form onSubmit={handleSubmit}>
        <DialogTitle>
          <Typography sx={{ fontSize: { md: '3em', lg: '2em' } }} variant="body2">
            Edit [ {assignmentContext.assignment.title} ]
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent>

          <Box display="flex" flexDirection="column" justifyContent="space-between">
            {
              assignmentContext.assignment.template.edit.formElements.map((element) => {

                if ('select' === element.type && 'enum' === element.valueType) {
                  return <FormControl className={classes.formControl}>
                    <SelectComponent data={element} enums={assignmentContext.assignment.enmus} initialValues={formData[element.id]} updateState={handleStateUpdate} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('select' === element.type && 'dependencies' === element.valueType) {
                  const value = String(element.id);
                  let formattedDependencies = [];
                  formattedDependencies[value] = dependencies.map((dependency) => {

                    if ('object' !== typeof dependency[element.value]) {
                      return { id: dependency[element.value], label: dependency[element.value] };
                    } else {
                      return dependency[element.value];
                    }

                  });
                  return <FormControl className={classes.formControl}>
                    <SelectComponent data={element} enums={formattedDependencies} updateState={handleStateUpdate} initialValues={formData[element.id]} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('selectChip' === element.type && 'enum' === element.valueType) {
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipComponent
                      data={element}
                      enums={assignmentContext.assignment.enmus}
                      updateState={handleStateUpdate}
                      initialValues={formData[element.id]}
                    />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }
                if ('selectChip' === element.type && 'dependencies' === element.valueType) {
                  const value = String(element.id);
                  let formattedDependencies = [];
                  formattedDependencies[value] = dependencies?.map((dependency) => {
                    return dependency[element.value];
                  });
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipComponent
                      data={element}
                      enums={formattedDependencies}
                      updateState={handleStateUpdate}
                      initialValues={formData[element.id]}
                    />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }
                if ('selectChip' === element.type && 'formData' === element.valueType) {
                  let value = formData?.hasOwnProperty(element.value) ? formData[element.value] : [];
  
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipComponent data={element} enums={value} updateState={handleStateUpdate} initialValues={formData[element.id]} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }
                if ('selectChipFreeSolo' === element.type) {
             
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipFreeSoloComponent data={element} enums={[]} updateState={handleStateUpdate} initialValues={formData[element.id]}/>
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('textInput' === element.type) {
                  return <FormControl className={classes.formControl}>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                      id={element.id} name={element.id} size="large" label={
                        <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                          {element.label}
                        </Typography>} variant="outlined" onChange={handleStateUpdate} value={formData[element.id]} />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text">{element.helperText}</FormHelperText>
                    )

                    }
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('checkbox' === element.type) {
                  return <FormControl className={classes.formControl}>
                          

                    <FormControlLabel control={<Checkbox
                                                id={element.id} name={element.id}
                                                checked={checked}
                                                onChange={handleChange}
                                                label={element.label}
                                                color="success"
                                                inputProps={{ 'aria-label': 'controlled' }}
                    />} label={
                      <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                        {element.label} </Typography>
                    } />

                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }


                if ('numberInput' === element.type) {
                  return <FormControl className={classes.formControl}>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                      id={element.id} name={element.id} size="large" label={element.label} variant="outlined" type="number" onChange={handleStateUpdate} value={formData[element.id]} />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text">{element.helperText}</FormHelperText>
                    )

                    }
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                return <></>;
              })
            }
          </Box>

        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" className={classes.footer}>
            <Button
              onClick={handleClose}
              size="large"
              variant="outlined"
              color="primary"
              className={classes.button}
              sx={{ fontSize: { md: '2em', lg: '1.25em' } }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              startIcon={<UpdateIcon />}
              sx={{ fontSize: { md: '2em', lg: '1.25em' } }}
            >
              Update
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  </>

}

export default EditComponent;
