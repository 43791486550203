import { createContext, useState, useEffect } from "react";
import AssignmentService from '../services/AssignmentService';

const CustomizedContentContext = createContext();

export const CustomizedContentProvider = ({ children }) => {

    const [showStatus, setShowStatus] = useState(-1);
    const [customizedContent, setCustomizedContent] = useState({});
    const [checkStatus, setCheckStatus] = useState(1);
    const [timerCount, setTimerCount] = useState(-100);
    const [playBackData, setPlayBackData] = useState({});

    useEffect(() => {
        setCheckStatus(1)
    }, [])




    useEffect(() => {
        const checkCustomizedContent = async () => {

            const response = await AssignmentService.checkCustomizedContent();
            let status = response?.data?.status;
            setCustomizedContent(response?.data?.data);
            setShowStatus(status);


            if (checkStatus === 1) {
                setTimerCount(response?.data?.data?.timeRemaining ?? 1800);
            }

            setPlayBackData({
                otp: response?.data?.data?.otp,
                playbackInfo: response?.data?.data?.playbackInfo
            })

        }

        checkCustomizedContent();
    }, [checkStatus]);

    return (<CustomizedContentContext.Provider
        value={
            {
                showStatus,
                customizedContent,
                checkStatus,
                setCheckStatus,
                timerCount,
                setTimerCount,
                playBackData,
                setShowStatus
            }
        }
    >
        {children}
    </CustomizedContentContext.Provider>)
}

export default CustomizedContentContext;

