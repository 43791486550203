import React, { useState, useEffect } from 'react';

// material-ui
import { Typography } from '@material-ui/core';

// project imports
import NavGroup from './NavGroup';
// import menuItem from './../../../../menu-items';
import CourseService from './../../../../services/CourseService';

import { FormattedMessage } from 'react-intl';

import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';

//-----------------------|| SIDEBAR MENU LIST ||-----------------------//

const initialMenuItems = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
      {
        id: 'dashboard',
        title: <FormattedMessage id="customized-content" />,
        type: 'item',
        url: '/pages/dashboard',
        icon: DashboardIcon,
        breadcrumbs: false,
      },
    ],
};

const MenuList = () => {
    const [menuItems, setMenuItems] = useState(initialMenuItems);

    useEffect(() => {
      const fetchCourseMenuItems = async () => {
        try {
          const response = await CourseService.getUserCourses();
          const courses = response.data.data;
  
          // Generate dynamic menu items
          const dynamicChildren = courses.map((course) => ({
            id: `course-${course.id}`,
            // title: <FormattedMessage id={course.name + " Assignments"} />,
            title: (
              <>
                {course.name} <FormattedMessage id="assignments" />
              </>
            ),
            type: 'item',
            url: `/pages/${course.id}/assignment/`,
            icon: AssignmentIcon,
            breadcrumbs: false,
          }));
  
          // Set menu items with dynamic children
          setMenuItems((prevState) => ({
            ...prevState,
            children: [...prevState.children, ...dynamicChildren],
          }));
        } catch (error) {
          console.error('Error fetching courses:', error);
        }
      };
  
      fetchCourseMenuItems();
    }, []);


      function render(item) {
        switch (item.type) {
          case 'group':
              return <NavGroup key={item.id} item={item} />;
          default:
              return (
                  <Typography key={item.id} variant="h6" color="error" align="center">
                      Menu Items Error
                  </Typography>
              );
      }
      }

    const navItems = render(menuItems);
    return navItems;
};

export default MenuList;
