import React, {useEffect,useState} from 'react'
import MainCard from '../../../ui-component/cards/MainCard';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Divider from '@material-ui/core/Divider';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import AssignmentService from '../../../services/AssignmentService';
import { Cookies } from 'react-cookie';
import Box from '@material-ui/core/Box';
import MuiAlert from '@material-ui/core/Alert';
import Chip from '@material-ui/core/Chip';
import DoneIcon from '@material-ui/icons/Done';
import LinearProgress from '@material-ui/core/LinearProgress';
import MarkAsComplteComponent from '../../../components/common/MarkAsComplteComponent';
import { useParams } from "react-router-dom";

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
    maxWidth: '100%',
  },
  inline: {
    display: 'inline',
  },
}));

function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}


const AssignmentList = () => {
  let { courseId } = useParams();
  const [results, setResults] = useState([]);
  const [errors, setError] = useState('');
  const [loading, setLoading] = useState(false);

  const cookies = new Cookies();
  const userId = cookies.get("blUserId");

  useEffect(() => {
      setLoading(true);
      AssignmentService.getUserAssignments(userId,courseId)
      .then((response) => {
 
          setResults(response.data.data);
          setLoading(false);
      })
      .catch(() => {
          setError({
              type: 'error',
              message: 'Assignment Load Error !'
          })
          setLoading(false);
      })
    }, [userId,courseId]);



 const classes = useStyles();
  return (    <>
    <MainCard sx={{ marginTop: { md: '10em', lg: '2.5em' } }}>
      <Typography variant="h1" gutterBottom>
      Assignments
    </Typography>
    <Box mt={2} mb={2}>
        {!!errors && (
            <Alert severity={errors.type}>{errors.message}</Alert>
        )}
         {!!loading && (
             <>
             <LinearProgress color="secondary"/>
             </>
         )}
    </Box>
    <Divider/>
    <List className={classes.root}>
    {
      
      results.map((result) => (
        <>
          <ListItem alignItems="flex-start" style={{ margin: '2em 0', backgroundColor: '#E8EAE3', padding: '2em 1em' }}>

            <Typography variant="div" gutterBottom style={{ width: '100%' }}>

                <Box 
                  component="img" 
                sx={{ width: { md: '10em', lg: '8em' }, marginBottom: '2em' }}
                  alt="image" 
                  src={'/assets/images/'+ result.task.name.trim().replace(/\s/g, "_",'g').toLowerCase() + '.png'}
                  gutterBottom
                />
              <Typography variant="h2" gutterBottom sx={{ fontSize: { md: '4em', lg: '2em' } }}>
                  {result.task.name}
                </Typography>
                <Typography variant="body2" gutterBottom style={{width: '100%', marginBottom: '2em' }}>
                <Typography variant="p" gutterBottom style={{ width: '100%' }} sx={{ fontSize: { md: '2em', lg: '1.5em' }, lineHeight: { md: '1.5em' } }}>
                      {result.task.description}
                  </Typography>
                </Typography>
                      <Typography variant="div" style={{ display: 'inline-flex', width: '100%', marginBottom: '2em' }}>
                      <Button  href={'/pages/assignment/' + result.userTask} variant="contained" color="secondary" style={{ width: '10rem' }} sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                          Open
                        </Button>
                       
                   
                        { (result.isCompletedValid && !result.isCompleted ) && (<MarkAsComplteComponent userId={userId} taskId={result.userTask} setLoading={setLoading} setError={setError}/>)}
                        
                      <Chip icon={<DoneIcon />} label="Completed" color="success" style={{ visibility: true === result.isCompleted ? 'show' : 'hidden', marginLeft: '2em', fontWeight: 'bold' }} sx={{ fontSize: { md: '2em', lg: '1em' }, padding: '1.5em 0' }} />
                      </Typography>
                      <Typography variant="div"  sx={{ fontSize: { md: '2em', lg: '1.5em' }, color: 'red'}}>
                        { (!result.isCompletedValid) && (`'Mark as Complete’ button will be available on ${result.isCompletedValidAt}. This is to provide you with the 
opportunity to refine your work before 
marking them as complete.`  )}
                        </Typography> 
                      
          
            </Typography>
      
            </ListItem>
            <Divider component="li" />
        </>
      ))
    }

    </List>
    </MainCard>
    </>
  )
}

export default AssignmentList;
