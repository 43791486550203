import React from 'react';
import { useSelector } from 'react-redux';

import { ThemeProvider } from '@material-ui/core/styles';
import { CssBaseline, StyledEngineProvider } from '@material-ui/core';

// routing
import Routes from './routes';

// defaultTheme
import theme from './themes';

// project imports
 import Locales from './ui-component/Locales';
import NavigationScroll from './layout/NavigationScroll';
// import RTLLayout from './ui-component/RTLLayout';


// auth provider
import {AuthProvider} from '../src/contexts/AuthContext';
import {CourseProvider} from '../src/contexts/CourseContext';
import { CookiesProvider } from 'react-cookie';

//-----------------------|| APP ||-----------------------//

const App = () => {
    const customization = useSelector((state) => state.customization);

    return (
        <StyledEngineProvider injectFirst>
            <ThemeProvider theme={theme(customization)}>
                <CssBaseline />
                <Locales>
                    <NavigationScroll> 
                    <CookiesProvider>
                    <AuthProvider>
                    <CourseProvider>
                        <Routes />
                        {/* <Snackbar /> */}
                    </CourseProvider>
                    </AuthProvider>
                    </CookiesProvider>
                  </NavigationScroll>
                </Locales>
            </ThemeProvider>
        </StyledEngineProvider>
    );
};

export default App;
