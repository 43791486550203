import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  resize: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5em'
    }
  },
  resizeOption: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2em',
      padding: '1em 0'
    }
  }
}));

const SelectChipFreeSoloComponent = ({data, enums = [], updateState, initialValues=[]}) => {
  const classes = useStyles();
  const initialValueOptions = initialValues === null ? [] : initialValues;
  const fixedOptions = enums;
  const [value, setValue] = React.useState([...initialValueOptions]);
 
  const handleKeyUp = event => {
    if(event.target.value.includes(","))
    {
      event.target.value = event.target.value.replace(",", "");
      event.preventDefault();
      event.stopPropagation();
      if (event.target.value.length > 0) {
        let newValue = [...value, event.target.value];
        setValue(newValue);
        newValue.forEach((element, index) => {
          if(false === element.hasOwnProperty('id')){
              newValue[index] = {id: element, label: element}
          }
        });
    
    
        updateState({
          target: {
            name: data.id,
            value: newValue
          }
        });

    
      }
    }
  
   
  }

  return <>
      <Autocomplete
      classes={{
        input: classes.resize,
        option: classes.resizeOption,
      }}
          multiple
          freeSolo
          id={data.id}
          name={data.id}
          value={value}
          onChange={(event, newValue) => {
  
            setValue([
              ...fixedOptions,
              ...newValue.filter(
                (option) => fixedOptions.indexOf(option) === -1
              ),
            ]);
            newValue.forEach((element, index) => {
                if(false === element.hasOwnProperty('id')){
                    newValue[index] = {id: element, label: element}
                }
            });

            const newEvent = {
              target: {
                name: data.id,
                value: newValue
              }
            }
            updateState(newEvent);
          }}
          options={enums}
          getOptionLabel={(option) => option}
          renderTags={(value, getTagProps) =>
            value?.map((option, index) => (
              <Chip
                variant="outlined"
                sx={{ fontSize: { md: '2em', lg: '1em' }, padding: '1em 0' }}
                label={
                  option.hasOwnProperty('label') ? option?.label : option} {...getTagProps({ index })
                } />
            ))
          }
          style={{ width: '100%' }}
          renderInput={(params) => {
            params.inputProps.onKeyUp = handleKeyUp;
           return (
             <TextField {...params}
               label={
                 <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                   {data.label}
                 </Typography>
               }
               variant="outlined" 
              placeholder={data.label} />
            )
          }}
        />
  </>
}
export default SelectChipFreeSoloComponent;
