import React, {useContext} from 'react'
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import EditComponent from './EditComponent';
import DeleteComponent from './DeleteComponent';
import { Typography } from '@material-ui/core';

import { AssignmentContext } from '../../contexts/AssignmentContext';

const ListItemComponent = ({data}) => {
    const assignmentContext = useContext(AssignmentContext);
    const results = [];
    for (const [key, value] of Object.entries(data)) {
        results.push(value);
        console.log(key);
    }

    const changeData = (data) => {
        const tempResult = [...assignmentContext.results];
        tempResult[data.index] = data;
        assignmentContext.setResults(tempResult);
    }

    const deleteItem = (data) => {
      const tempResult = [...assignmentContext.results];
      tempResult[data.index] = data;
      tempResult.splice(data.index, 1);
      assignmentContext.setResults(tempResult);
    }

    return  <>
        <TableContainer component={Paper}>
            <Table aria-label="simple table">
                <TableHead>
                    <TableRow>
                        <TableCell>
                            <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold' }} variant="body2">
                                Id
                            </Typography>
                        </TableCell>
                        <TableCell>
                            <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold' }} variant="body2">
                                Role
                            </Typography>
                        </TableCell>
                        <TableCell align="right">
                            <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold' }} variant="body2">
                                Priority
                            </Typography>
                        </TableCell>
                        <TableCell align="right"></TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {
                        results.map((result, index) => (
                            <TableRow key={result.role} >
                                <TableCell sx={{ fontSize: { md: '2em', lg: '1em' } }}>{++index}</TableCell>
                                <TableCell sx={{ fontSize: { md: '2em', lg: '1em' } }}>{result.role}</TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{result.priority}</TableCell>
                                <TableCell align="right" sx={{ fontSize: { md: '2em', lg: '1em' }, display: 'flex' }}>
                                    <EditComponent data={result} editFunc={changeData} />
                                    <DeleteComponent data={result} deleteFunc={deleteItem} />
                                </TableCell>
                            </TableRow>
                        ))
                    }
                </TableBody>
            </Table>
        </TableContainer>
      </>

}

export default ListItemComponent;
