import React, { lazy } from 'react';
import { Route, Switch, useLocation } from 'react-router-dom';
import Loadable from '../ui-component/Loadable';

// project imports
import MinimalLayout from './../layout/MinimalLayout';

// load auth routes
const AuthLogin = Loadable(lazy(() => import('../views/pages/authentication/login/index')));
const AuthRegister = Loadable(lazy(() => import('../views/pages/authentication/register/Register')));
const AuthForgotPassword = Loadable(lazy(() => import('../views/pages/authentication/forgot-password-request/index')));
const AuthResetPassword = Loadable(lazy(() => import('../views/pages/authentication/reset-password/index')));
const CommonInfoPage = Loadable(lazy(() => import('../views/pages/authentication/CommonInfoPage')));
// const AuthCheckMail = Loadable(lazy(() => import('../views/pages/authentication/authentication1/CheckMail1')));
// const AuthResetPassword = Loadable(lazy(() => import('../views/pages/authentication/authentication1/ResetPassword1')));
// const AuthCodeVerification = Loadable(lazy(() => import('../views/pages/authentication/authentication1/CodeVerification1')));
//-----------------------|| AUTHENTICATION ROUTING ||-----------------------//

const AuthenticationRoutes = () => {
    const location = useLocation();
    
    return (
        <Route
            path={
                [
                    '/login',
                    '/register',
                    '/forgot-password',
                    '/reset-password',
                    '/common-info'
                    // '/pages/check-mail/check-mail',
                    // '/pages/reset-password/reset-password',
                    // '/pages/code-verification/code-verification',
                ]
            }
        >
            <MinimalLayout>
                <Switch location={location} key={location.pathname}>
                    <Route path="/login" component={AuthLogin} />
                    <Route path="/register" component={AuthRegister} />
                    <Route path="/forgot-password" component={AuthForgotPassword} />
                    <Route path="/reset-password/:token" component={AuthResetPassword} />
                    <Route path="/common-info" component={CommonInfoPage} />
                    {/* <Route path="/pages/check-mail/check-mail" component={AuthCheckMail} />
                    <Route path="/pages/reset-password/reset-password" component={AuthResetPassword} />
                    <Route path="/pages/code-verification/code-verification" component={AuthCodeVerification} /> */}
                </Switch>
            </MinimalLayout>
        </Route>
    );
};

export default AuthenticationRoutes;
