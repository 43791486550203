import { Container } from '@material-ui/core'
import React from 'react'
import CustomizedContentComponent from '../../../components/CustomizedContentComponent'
import { CustomizedContentProvider } from '../../../contexts/CustomizedContentContext'

function CustomizedContentPage() {
    return <>
        <CustomizedContentProvider>
            <Container sx={{ marginTop: { md: '10em', lg: '2.5em' } }}>
                <CustomizedContentComponent />
            </Container>
        </CustomizedContentProvider>
    </>
}

export default CustomizedContentPage