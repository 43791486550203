
import axiosServices from '../utils/axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const langService =  {
    getUserCourses: () => {
      return axiosServices.get('/api/user-courses/',
        { 
          headers: {
            Authorization : `Bearer ${cookies.get("blToken")}`
          }
        }
      );
      
    },
    setUserLang: (lang) => {
      cookies.set('lang', lang, { path: '/' });
      return axiosServices.post(
        '/api/lang',
        { lang: lang},
        {
          headers: {
            Authorization: `Bearer ${cookies.get("blToken")}`
          }
        }
      );
    }

};

export default langService;
