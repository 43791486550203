import axios from 'axios';
import Cookies from 'js-cookie';

const axiosServices = axios.create({
    baseURL: process.env.REACT_APP_API_URL,
    withCredentials: true
});

axiosServices.interceptors.response.use(function (response) {
    return response;
  }, function (error) {
    console.log("error here");

    if (error.response.status=== 401) {
      // Clear all cookies
      Cookies.remove('blUserId');
      Cookies.remove('blUserEmail');
      Cookies.remove('blUserName');
      Cookies.remove('blToken');
      Cookies.remove('lang');
      // Redirect to login page
      window.location.href = '/login';
    }
    // }else if(error.response.status=== 403){
    //   window.location.href= 'https://www.braincipher.com';
    // }
    return Promise.reject(error);
  });
export default axiosServices;
