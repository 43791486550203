import React, {useState, useContext, useEffect} from 'react'
import { AssignmentContext } from '../../contexts/AssignmentContext';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemSecondaryAction from '@material-ui/core/ListItemSecondaryAction';
import ListItemText from '@material-ui/core/ListItemText';
import DeleteComponent from './DeleteComponent';
import { Typography } from '@material-ui/core';

const setBoxItems = (boxNumber, results) => {
      return results
      .map((element, index) => {
        element['index'] = index;
        return element;
      })
      .filter((element) =>element.category.id === boxNumber);
}

const JohariWindowDetailComponent = ({data}) => {
  const assignmentContext = useContext(AssignmentContext);
  // box states
  const [boxOneItems, setBoxOneResults] = useState([]);
  const [boxTwoItems, setBoxTwoResults] = useState([]);
  const [boxThreeItems, setBoxThreeResults] = useState([]);
  const [boxFourItems, setBoxFourResults] = useState([]);

  useEffect(() => {
    setBoxOneResults(setBoxItems(1, assignmentContext.results));
    setBoxTwoResults(setBoxItems(2, assignmentContext.results));
    setBoxThreeResults(setBoxItems(3, assignmentContext.results));
    setBoxFourResults(setBoxItems(4, assignmentContext.results));
  }, [assignmentContext.results])


  const deleteItem = (data) => {
      const tempResult = [...assignmentContext.results];
      tempResult.splice(data.index, 1);
      assignmentContext.setResults(tempResult);
  }

  return (<>
     <Paper style={{  width: "100%", overflow: "auto"}}>
      <Table aria-label="simple table" style={{ minWidth: 700 }} sx={{ fontSize: { md: '2em' } }}>
        <TableHead stickyHeader>
          <TableRow style={{minHeight: '2rem'}}>
            <TableCell borderColor={'black'} style={{ width: '20%', backgroundColor: 'gray', color: 'white'}}></TableCell>
            <TableCell align="center" style={{ backgroundColor: 'gray', color: 'white', minHeight: '2rem' }}>
              <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, color: 'white' }} variant="body2">
                Known To self
              </Typography>
            </TableCell>
            <TableCell align="center" style={{ backgroundColor: 'gray', color: 'white' }}>
              <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, color: 'white' }} variant="body2">
                Not Known to self
              </Typography>
            </TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
            <TableRow key={1}>
            <TableCell style={{ backgroundColor: 'gray', color: 'white' }} align="center" stickyHeader component="th" scope="row">
              <strong>
                <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, color: 'white' }} variant="body2">
                  Known to others
                </Typography>
              </strong>
            </TableCell>
              <TableCell style={{ backgroundColor: '#fdfdfd' }} align="center">
                  <List style={{ borderColor:"grey.500"}}>
                    {
                      boxOneItems.map((item) => (
                        <ListItem style={{ backgroundColor: "#e8e8e8", padding: "1em", marginTop: "0.5em" }} >
                          <ListItemText 
                            primary={<Typography sx={{ fontSize: { md: '2em', lg: '1em' } }} variant="body2">{item.question.label}</Typography>}
                            />
                            <ListItemSecondaryAction>
                              <DeleteComponent data={item} deleteFunc={deleteItem}/>
                            </ListItemSecondaryAction>
                        </ListItem>
                      ))
                    }
                  </List>
              </TableCell>
              <TableCell style={{ backgroundColor: '#eeeeee' }} align="center">
                  <List style={{ borderColor:"grey.500"}}>
                  {
                    boxTwoItems.map((item) => (
                      <ListItem style={{backgroundColor:"white", padding:"1em", marginTop: "0.5em"}}>
                          <ListItemText
                          primary={<Typography sx={{ fontSize: { md: '2em', lg: '1em' } }} variant="body2">{item.question.label}</Typography>}
                          />
                          <ListItemSecondaryAction>
                            <DeleteComponent data={item} deleteFunc={deleteItem}/>
                          </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  }
                  </List>
              </TableCell>
            </TableRow>
            <TableRow key={1}>
            <TableCell style={{ backgroundColor: 'gray', color: 'white' }} align="center" stickyHeader component="th" scope="row">
              <strong>
                <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, color: 'white' }} variant="body2">
                  Not known to others
                </Typography>
              </strong>
            </TableCell>
              <TableCell style={{ backgroundColor: '#eeeeee' }} align="center">
                  <List style={{ borderColor:"grey.500"}}>
                  {
                    boxThreeItems.map((item) => (
                      <ListItem style={{backgroundColor:"white", padding:"1em", marginTop: "0.5em"}}>
                          <ListItemText
                          primary={<Typography sx={{ fontSize: { md: '2em', lg: '1em' } }} variant="body2">{item.question.label}</Typography>}
                          />
                          <ListItemSecondaryAction>
                          <DeleteComponent data={item} deleteFunc={deleteItem}/>
                          </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  }
                  </List>
              </TableCell>
              <TableCell style={{ backgroundColor: 'gray' }} align="center">
                  <List style={{ borderColor:"grey.500"}}>
                  {
                    boxFourItems.map((item) => (
                      <ListItem style={{backgroundColor:"white", padding:"1em", marginTop: "0.5em"}}  mt={1}>
                          <ListItemText
                          primary={<Typography sx={{ fontSize: { md: '2em', lg: '1em' } }} variant="body2">{item.question.label}</Typography>}
                          />
                          <ListItemSecondaryAction>
                            <DeleteComponent data={item} deleteFunc={deleteItem}/>
                          </ListItemSecondaryAction>
                      </ListItem>
                    ))
                  }
                  </List>
              </TableCell>
            </TableRow>

        </TableBody>
      </Table>
     </Paper>
     </>
  )
}


export default JohariWindowDetailComponent;
