import { Container } from '@material-ui/core'
import React from 'react'
import CustomizedLessonComponent from '../../../components/customizedLessons/CustomizedLessonComponent'
import { CustomizedLessonsProvider } from '../../../contexts/CustomizedLessonsContext'
import { useParams } from "react-router-dom";

function CustomizedLessionPage() {
    let { courseId } = useParams();
    return <>
        <Container sx={{ marginTop: { md: '10em', lg: '2.5em' }}}>
            <CustomizedLessonsProvider courseId={courseId}>
                <CustomizedLessonComponent  />
            </CustomizedLessonsProvider>
        </Container>
    </>
}

export default CustomizedLessionPage