import axiosServices from '../utils/axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const AssignmentService =  {

    saveAssignmentData: (assignment) => {      
        return axiosServices.post(
          '/api/user-tasks/',
          assignment,
          { headers: {
            Authorization : `Bearer ${cookies.get("blToken")}`
        }}
        );
    },

    getAssignment: (userId, taskId) => {

      return axiosServices.get('/api/user-task/',
        { 
          params: { task: taskId }, 
          headers: {
            Authorization : `Bearer ${cookies.get("blToken")}`
         }
        }
      );
    },
    getUserAssignments: (userId, courseId) => {
      return axiosServices.get('/api/'+courseId+'/user-tasks/',
        { 
          headers: {
            Authorization : `Bearer ${cookies.get("blToken")}`
          }
        }
      );
      
    },
  calculateCustomizedContent: (results, timeZoneData) => {      
      return axiosServices.post(
        '/api/user-task/customized-content/',
        { results, timeZoneData },
        { headers: {
          Authorization : `Bearer ${cookies.get("blToken")}`
      }}
      );
    },
  checkCustomizedContent: () => {      
      return axiosServices.post(
        '/api/user-task/customized-content-check/',
        {},        
        { headers: {
          Authorization : `Bearer ${cookies.get("blToken")}`
      }}
      );
    },
  updateCustomizedContent: (contentId, timerCount) => {      
      return axiosServices.post(
        '/api/user-task/customized-content-update/',
       { contentId: contentId, timerCount: timerCount},
        { headers: {
          Authorization : `Bearer ${cookies.get("blToken")}`
      }}
      );
    },
    assignemntMarkAsComplete: (userId, taskId ) => {      
      return axiosServices.post(
        '/api/user-task/mark-as-complete/',
        { task: taskId},
        { headers: {
          Authorization : `Bearer ${cookies.get("blToken")}`
      }}
      )
    },
  checkCustomizedLessons: (courseId) => {
    return axiosServices.get(
      '/api/customizedLesson/'+courseId,
      {
        headers: {
          Authorization: `Bearer ${cookies.get("blToken")}`
        }
      }
    );
  },
  markAsCompleteCustomizedLessons: (id) => {
    return axiosServices.post(
      '/api/customizedLesson/markAsComplete',
      { id: id},
      {
        headers: {
          Authorization: `Bearer ${cookies.get("blToken")}`
        }
      }
    );
  },

};

export default AssignmentService;
