import React , { useContext } from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import FormControl from '@material-ui/core/FormControl';
import AddIcon from '@material-ui/icons/Add'
import Divider from '@material-ui/core/Divider';
import SelectComponent from './../SelectComponent';
import SelectChipComponent from './../SelectChipComponent';
import SelectChipFreeSoloComponent from './../SelectChipFreeSoloComponent';
import TextField from '@material-ui/core/TextField';
import Button from '@material-ui/core/Button';
import FormHelperText from '@material-ui/core/FormHelperText';
import Checkbox from '@material-ui/core/Checkbox';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import { AssignmentContext } from '../../contexts/AssignmentContext';

// import { useForm } from "react-hook-form";
import { validate } from '../../utils/assignmentInputValidators';
import { DialogContentText, Typography } from '@material-ui/core';



// import _ from 'lodash';

const useStyles = makeStyles((theme) => ({
  container: {
    margin: '1em 0',
  },
  btnSuccess: {
    color: 'white'
  },
  formControl: {
    margin: '1em 0'
  },
  button: {
    margin: '0 2em'
  },
  footer: {
    width: '95%',
    margin: '0 auto',
  },
  error: {
    color: 'red',
    marginBottom: '0px',
    marginTop: '5px',
    marginLeft: '5px'
  },
  resize: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5em'
    }
  }
}));

const AddNewComponent = ({ data, addFunc, dependencies, existingData }) => {
  const classes = useStyles();
  const [open, setOpen] = React.useState(false);
  const [formData, changeFormData] = React.useState(data?.template?.addNew?.response)
  const assignmentContext = useContext(AssignmentContext);

  const [errors, setErrors] = React.useState({});

  const handleClickOpen = () => {
    changeFormData(data?.template?.addNew?.response)
    setErrors({})
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    const validation = validate(data, formData, existingData);

    if (Object.keys(validation).length === 0) {
      addFunc(formData);
      handleClose();
    } else {
      //setting the errors returned from validation
      setErrors(validation);
      console.log('errors....', errors)
    }
  }

  const handleStateUpdate = (event) => {
    // remove errors of specific field
    delete errors[event.target.name]
    setErrors(errors)
    let formD = { ...formData };
    formD[event.target.name] = event.target.value;
    validateState(formD);
    changeFormData(formD);
  }

  const [checked, setChecked] = React.useState(false);

  const handleChange = (event) => {
    setChecked(event.target.checked);
    let formD = { ...formData };
    formD[event.target.name] = !checked;
    validateState(formD);
    changeFormData(formD);
  };


  const validateState = (formD) => {
    if('Dream Validation' === data?.title)
    {
      let requiredQualities = formD?.question ?? [];
      let otherRequiredQualities = formD?.qualities ?? [];
      let benificialQualities = formD?.benificial_qualities ?? [];
      let otherbBenificialQualities = formD?.other_benificial_qualities ?? [];

      let allRequiredQualities = (requiredQualities?.concat(otherRequiredQualities))?.map(value => value.id);
      let allBenificialQualities = benificialQualities?.concat(otherbBenificialQualities)?.map(value => value.id);;
      let unionQualities = allRequiredQualities.filter(x => allBenificialQualities.includes(x));
      let percentage = (unionQualities?.length / allRequiredQualities?.length) * 100 ?? 0;
      let color = percentage > 60 ? 'error' : 'default';
      let text =  percentage > 60 ? 'Awesome' : '';

      if (true === formD?.aligned) {
        color = 'success';
        text = 'aligned';
      }
      
      formD['state'] = {percentage: percentage , color: color, text: text};
    }

    if('Flow state' === data?.title)
    {
      let color ='default';
      let text =  '';
      let percentage = 0;
      if (true === formD?.aligned) {
        color = 'success';
        text = 'aligned';
      }
      
      formD['state'] = {percentage: percentage , color: color, text: text};
    }
  }



  return <>
    <Box display="flex" justifyContent="flex-end" className={classes.container}>
      <Button variant="contained" size="large" color="success" sx={{ width: { md: '100%', lg: '20%' } }} className={classes.btnSuccess} onClick={handleClickOpen} style={{ display: true === assignmentContext?.taskCompleted ? 'none' : 'flex' }}>
        <Typography sx={{ fontSize: { md: '3em', lg: '1.25em' }, color: 'white' }} variant="body2">
          Add New
        </Typography>
      </Button>
    </Box>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth='true'>
      <form onSubmit={handleSubmit}  >
        <DialogTitle >
          <Typography sx={{ fontSize: { md: '3em', lg: '2em' } }} variant="body2">
            Add New [ {data.title} ]
          </Typography>
        </DialogTitle>
        <Divider />
        <DialogContent sx={{ padding: { md: '5em 2em' } }}>

          <Box display="flex" flexDirection="column" justifyContent="space-between" >
            {
              data?.template?.addNew?.formElements?.map((element) => {

                if ('select' === element.type && 'enum' === element.valueType) {
                  return <FormControl className={classes.formControl} >
                    <SelectComponent data={element} enums={data.enmus} updateState={handleStateUpdate} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('select' === element.type && 'dependencies' === element.valueType) {
                  const value = String(element.id);
                  let formattedDependencies = [];
                  formattedDependencies[value] = dependencies.map((dependency) => {

                    if ('object' !== typeof dependency[element.value]) {

                      return { id: dependency[element.value], label: dependency[element.value] };
                    } else {
                      return dependency[element.value];
                    }

                  });
                  return <FormControl className={classes.formControl}>
                    <SelectComponent data={element} enums={formattedDependencies} updateState={handleStateUpdate} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('selectChip' === element.type && 'enum' === element.valueType) {

                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipComponent data={element} enums={data.enmus} updateState={handleStateUpdate} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('selectChipFreeSolo' === element.type) {

                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipFreeSoloComponent data={element} enums={[]} updateState={handleStateUpdate} />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.helperText}</FormHelperText>
                    )}
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('selectChip' === element.type && 'formData' === element.valueType) {
                  let value = formData?.hasOwnProperty(element.value) ? formData[element.value] : [];
  
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                    <SelectChipComponent data={element} enums={value} updateState={handleStateUpdate} />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.helperText}</FormHelperText>
                    )}
                  </FormControl>
                }

                if ('selectChip' === element.type && 'dependencies' === element.valueType) {
                
                  const value = String(element.id);
                  let formattedDependencies = [];
                  formattedDependencies[value] = dependencies.map((dependency) => {
                    return dependency[element.value];
                  });
            
                  return <FormControl className={classes.formControl} style={{ marginTop: '2em' }} variant="outlined">
                  
                    <SelectChipComponent data={element} enums={formattedDependencies} updateState={handleStateUpdate} />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.helperText}</FormHelperText>
                    )}
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('textInput' === element.type) {
                  return <FormControl className={classes.formControl}>
                    <TextField id={element.id} InputProps={{
                      classes: {
                        input: classes.resize,
                      },
                    }} name={element.id} size="large"
                      label={<Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.label}</Typography>} variant="outlined" onChange={handleStateUpdate} />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.helperText}</FormHelperText>
                    )

                    }
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('checkbox' === element.type) {
                  return <FormControl className={classes.formControl}>
                          
                    <FormControlLabel control={
                      <Checkbox
                        id={element.id} name={element.id}
                        checked={checked}
                        onChange={handleChange}
                        label={element.label}
                        color="success"
                        inputProps={{ 'aria-label': 'controlled' }}
                      />}
                      label={
                        <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                          {element.label}
                        </Typography>

                      }
                    />
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                if ('numberInput' === element.type) {
                  return <FormControl className={classes.formControl}>
                    <TextField
                      InputProps={{
                        classes: {
                          input: classes.resize,
                        },
                      }}
                      id={element.id} sx={{ fontSize: { md: '2em', lg: '1em' } }} name={element.id} size="large" label={<Typography sx={{ fontSize: { md: '3em', lg: '2em' } }}>{element.label}</Typography>} variant="outlined" onChange={handleStateUpdate} type="number" />
                    {!!element.helperText && (
                      <FormHelperText id="my-helper-text" sx={{ fontSize: { md: '2em', lg: '1em' } }}>{element.helperText}</FormHelperText>
                    )

                    }
                    {errors[element.label] ? <DialogContentText className={classes.error}>{errors[element.label]}</DialogContentText> : null}
                  </FormControl>
                }

                return <></>;

              })
            }
          </Box>

        </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" sx={{ width: '100%' }}>
            <Button
              onClick={handleClose}
              size="large"
              variant="outlined"
              color="primary"
              className={classes.button}
              sx={{ fontSize: { md: '2em', lg: '1.25em' } }}
            >
              Cancel
            </Button>
            <Button
              variant="contained"
              color="primary"
              size="large"
              type="submit"
              startIcon={<AddIcon />}
              sx={{ fontSize: { md: '2em', lg: '1.25em' } }}
            >
              Add New
            </Button>
          </Box>
        </DialogActions>
      </form>
    </Dialog>
  </>

}

export default AddNewComponent;
