// third-party
import { FormattedMessage } from 'react-intl';

import AssignmentIcon from '@material-ui/icons/Assignment';
import DashboardIcon from '@material-ui/icons/Dashboard';


//-----------------------|| SAMPLE PAGE & DOCUMENTATION MENU ITEMS ||-----------------------//

export const other = {
    id: 'sample-docs-roadmap',
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="Customized Content" />,
            type: 'item',
            url: '/pages/dashboard',
            icon: DashboardIcon,
            breadcrumbs: false
        },
        {
            id: 'assignment',
            title: <FormattedMessage id="Assignments" />,
            type: 'item',
            url: '/pages/assignment',
            icon: AssignmentIcon,
            breadcrumbs: false
        }
    ]
};
