import { Button, Grid, Paper, Typography } from '@material-ui/core'
import { useContext } from 'react'
import { FormattedMessage } from 'react-intl';

import CustomizedLessonsContext from '../../contexts/CustomizedLessonsContext'
import './customizedLessons.css';


function Iframe(props) {
    return (
        <div
        style={{width: '100%'}}
            dangerouslySetInnerHTML={{ __html: props.iframe ? props.iframe : "" }}
        />
    );
}


function CustomizedLessonComponent() {

    const { lessonIFrame, markAsCompleteLesson, lessonCheck, responseMessage, lessonTitle, lessonDescription, lessonImage } = useContext(CustomizedLessonsContext)

    return <>
        <Grid justifyContent="center" alignItems="center" style={{ display: lessonCheck === -1 ? 'flex' : 'none' }}>
            <Paper variant="outlined" square sx={{ display: 'flex', padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
            <FormattedMessage id="checking-customized-lessons" />
            </Paper>
        </Grid>

        <Grid justifyContent="center" alignItems="center" style={{ display: lessonCheck === 25 ? 'flex' : 'none' }}>
            <Paper variant="outlined" square sx={{ display: 'flex', padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                <p>{responseMessage}</p>
            </Paper>
        </Grid>
        <Grid justifyContent="center" alignItems="center" style={{ display: lessonCheck === 0 ? 'flex' : 'none', padding: '1em' }}>
        <Paper variant="outlined" square sx={{ padding: '3em', display: 'flex' , flexDirection: 'column', alignItems: 'center'}}>
                <h1 >{lessonTitle}</h1>
                <img src={lessonImage} style={{width: '100%', margin: '3em 0'}} alt='Lesson'/>
                <Iframe iframe={lessonIFrame} allow="autoplay" sx={{width: '100%', padding: '3em'}}/>
                <Typography lineHeight={2} sx={{margin: '3em 0'}}>{lessonDescription}</Typography>
        </Paper>
        </Grid>
        
        <Grid justifyContent="center" alignItems="center" style={{ display: lessonCheck === 0 ? 'flex' : 'none', padding: '1em' }}>
            <Button color="secondary" variant="outlined" onClick={markAsCompleteLesson}>Mark As Complete</Button>
        </Grid>

        <Grid justifyContent="center" alignItems="center" style={{ display: lessonCheck === 2 ? 'flex' : 'none' }}>
            <Paper variant="outlined" square sx={{ display: 'flex', padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                Processing ... 
            </Paper>
        </Grid>
    </>

}

export default CustomizedLessonComponent