import React, { createContext, useState } from 'react';
import { useCookies, Cookies } from 'react-cookie';
import axiosServices from '../utils/axios';
import { useHistory } from "react-router-dom";
import config from './../config';

const initialState = {
    isLoggedIn: false,
    isInitialized: false,
    user: null
};

const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => { },
    register: () => Promise.resolve(),
    resetPassword: () => Promise.resolve(),
    errors: ''
});

export const AuthProvider = ({ children }) => {
    const [errors, setError] = useState('')
    const [cookies, setCookie, removeCookie] = useCookies(['name']);
    let history = useHistory();

    const login = async ({ email, password }) => {

        try {

            setError('');
            const response = await axiosServices.post('/login', { email, password }, { withCredentials: false });
            if (response.data.user != null) {
                setCookie('blUserId', response.data.user.iri, { path: '/', maxAge: 3600 });
                setCookie('blUserEmail', response.data.user.email, { path: '/', maxAge: 3600 });
                setCookie('blUserName', response.data.user.name, { path: '/', maxAge: 3600 });
                setCookie('blToken', response.data.user.token, { path: '/', maxAge: 3600 });
                setCookie('lang', response.data.user.lang, { path: '/', maxAge: 3600 });
                history.push(config.defaultPath);
                return { status: 'success', message: 'logged in successfully!' };
            } else {
                // return { status: 'error', message: response.message ?? 'Error logging in' };
                history.push('/common-info',
                {
                    nextRoute: 'login',
                    title: 'Error',
                    description: response.message ?? 'Error logging in',
                    status: 'error'
                })
                return { status: 'error', message: response.message ?? 'Error logging in' };
            }

        } catch (err) {
            console.log('authcontext:', err)
            if (err?.response?.data?.message) {
                setError({
                    type: 'error',
                    message: err.response.data.message
                })
                history.push('/common-info',
                {
                    nextRoute: 'login',
                    title: 'Error',
                    description: err.response.data.message,
                    status: 'error'
                })
            }
            return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };
        }

    };

    const logout = async () => {
        const cookies = new Cookies();
        try {
            await axiosServices.get('/logout', 
            { headers: {
                Authorization : `Bearer ${cookies.get("blToken")}`
            }}
            );
            removeCookie('blUserId', {path: '/'});
            removeCookie('blUserEmail', {path: '/'});
            removeCookie('blUserName', {path: '/'});
            history.push('/login');
        } catch (err) {
            return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };
        }
    
    };

    const register = async (email, password) => {
        try {
            await axiosServices.post('/register', { email, password }, { withCredentials: false });
            // history.push('/login');
            history.push('/common-info',
            {
                nextRoute: 'login',
                title: 'Registration Success',
                description: 'You have successfully registered with BrainLabs',
                status: 'success'
            })
            return { status: 'success', message: 'Registered successfully!' };
        } catch (err) {
            if (err?.response?.data?.message) {
                setError({
                    type: 'error',
                    message: err.response.data.message
                })
                console.log(err.response.data.message)
                history.push('/common-info',
                {
                    nextRoute: err.response.data.message.includes('braincipher.com') ? null : 'login',
                    redirect: err.response.data.message.includes('braincipher.com') ? 'https://www.braincipher.com' : null,
                    title: 'Error',
                    description: err.response.data.message,
                    status: 'error'
                })
            }
            // return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };

        }
    };

    const resetPasswordValidate = async (email) => {
        try {
            await axiosServices.post('/reset-password-validate', email, { withCredentials: false });
            // history.push('/login');
            history.push('/common-info',
                {
                    nextRoute: 'login',
                    title: 'Password Reset Email Sent',
                    description: 'An email with the password reset link has been sent. Please click the link in the email to reset your password',
                    status: 'success'
                })
            return { status: 'success', message: 'An email has been sent with the password reset link!' };

        } catch (err) {
            console.log('error.....', err, err.response)
            if (err?.response?.data?.message) {
                setError({
                    type: 'error',
                    message: err.response.data.message
                })
            }
            if (err?.response?.status === 402) {
                history.push('/common-info',
                    {
                        redirect: 'http://braincipher.com',
                        title: 'You have not registered',
                        description: 'Please register on Briancipher.com!',
                        status: 'error'
                    })

            } else {
                history.push('/common-info',
                    {
                        nextRoute: 'login',
                        title: 'Something went wrong :(',
                        description: 'Please contact the administrator!',
                        status: 'error'
                    })
            }

            return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };

        }
        // return {status:'success',message:'ok'}
    }

    const resetPassword = async (token, password) => {
        try {
            await axiosServices.post('/reset-password', { token, password: password.password }, { withCredentials: false });
            history.push('/login');
            return { status: 'success', message: 'You have successfully reset your password!' };
        } catch (err) {
            console.log('error.....', err)
            if (err?.response?.data?.message) {
                setError({
                    type: 'error',
                    message: err.response.data.message
                })
            }
            return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };

        }
        // return {status:'success',message:'ok'}
    }


    const validateToken = async (token, password) => {
        try {
            let res = await axiosServices.post('/validate-reset-password-url', { token }, { withCredentials: false });
            console.log('res...', res)
            // history.push('/login');
            if(res.data.status){
                return { status: 'success', message: 'Token is valid!' };
            }else{
                return { status: 'error', message: 'Token is invalid!' };
            }
        } catch (err) {
            console.log('error.....', err)
            if (err?.response?.data?.message) {
                setError({
                    type: 'error',
                    message: err.response.data.message
                })
            }
            return { status: 'error', message: err?.response?.data?.message ?? 'Something went wrong :(' };

        }
        // return {status:'success',message:'ok'}
    }

    return <AuthContext.Provider value={{ login, logout, register, resetPasswordValidate, resetPassword, validateToken, errors, cookies }}>{children}</AuthContext.Provider>;
};

export default AuthContext;
