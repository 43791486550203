import React from 'react';
import { Link } from 'react-router-dom';

// material-ui
import { ButtonBase } from '@material-ui/core';

// project imports
import config from './../../../config';
import logo from '../../../assets/images/logo.png';

//-----------------------|| MAIN LOGO ||-----------------------//

const LogoSection = () => {
    return (
        <ButtonBase
            disableRipple component={Link} to={config.defaultPath}>
            <img src={logo} alt="Logo" width={'100%'} />
        </ButtonBase>
    );
};

export default LogoSection;
