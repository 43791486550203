import React, { useCallback, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import Box from '@material-ui/core/Box';
import MobileStepper from '@material-ui/core/MobileStepper';
import Paper from '@material-ui/core/Paper';
import Button from '@material-ui/core/Button';
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft';
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight';
import { alpha } from '@material-ui/core';
import { useTheme } from '@material-ui/core/styles';
import { stepsSI, stepsEN, timeRanges } from './CustomizedContentWizard';
import Radio from '@material-ui/core/Radio';
import RadioGroup from '@material-ui/core/RadioGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import FormControl from '@material-ui/core/FormControl';
import FormLabel from '@material-ui/core/FormLabel';
import StopIcon from '@material-ui/icons/Stop';
import AssignmentService from '../../services/AssignmentService';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import Select from '@material-ui/core/Select';
import CustomizedContentContext from '../../contexts/CustomizedContentContext';
import { makeStyles } from '@material-ui/core/styles';
import { FormattedMessage } from 'react-intl';

const useStyles = makeStyles((theme) => ({
    radioButtonCustom: {
        "& svg": {
            [theme.breakpoints.down('lg')]: {
                fontSize: '2em',
                marginRight: '1em'
            }
        }
    }
}))

function CustomizedContentWizardComponent() {

    const [cookies] = useCookies(['lang']);
    const lang = cookies['lang'];
    let steps = stepsSI;
    
    if(lang === 'en'){
        steps = stepsEN;
    }
    const { setCheckStatus } = useContext(CustomizedContentContext);
    const classes = useStyles();
    let date = new Date();
    date.setDate(date.getDate() + 1);
    let nextDate = date.toDateString();

    const theme = useTheme();
    const [activeStep, setActiveStep] = React.useState(0);

    const [activeAnswer, setActiveAnswer] = React.useState('Q1_0');
    const [repeatTime, setRepeatTime] = React.useState('');
    const [submitButton, setSubmitButton] = React.useState(true);

    const [userAnswers, setAnswers] = React.useState({
        'Q1': 'Q1_0',
        'Q2': 'Q2_0',
        'Q3': 'Q3_0',
        'Q4': 'Q4_0',
        'Q5': 'Q5_0',
    });

    // Form validation 
    const formValidation = useCallback(() => {
        if (repeatTime) {
            setSubmitButton(false);
        } else {
            setSubmitButton(true);
        }
    }, [repeatTime, setSubmitButton])

    React.useEffect(() => {
        formValidation();
    }, [repeatTime, formValidation])


    const maxSteps = steps.length;

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
        if (activeStep >= 0) {
            let answer = userAnswers['Q' + (activeStep + 1)];
            setActiveAnswer(answer);
        }
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
        if (activeStep >= 0) {
            let answer = userAnswers['Q' + (activeStep - 1)];
            setActiveAnswer(answer);
        }
    };

    const handleRadioChange = (event) => {
        let valuesArray = event.target.value.split('_');
        let questionId = valuesArray[0];
        let ansersCopy = userAnswers;
        ansersCopy[questionId] = event.target.value;
        setActiveAnswer(event.target.value);
        setAnswers(ansersCopy)
    }


    const handleTimeChange = (event) => {
        setRepeatTime(event.target.value);
    };

    const saveAssignmentData = () => {

        const timeZoneData = { timeZone: Intl.DateTimeFormat().resolvedOptions().timeZone, repeatTime };

        AssignmentService.calculateCustomizedContent(userAnswers, timeZoneData)
            .then(() => {
                setCheckStatus(6);
                window.location.reload();
            })
            .catch((e) => {
            });
    }


    const selectAnswers = (questionNumber, answers, activeAnswer) => {


        switch (questionNumber) {
            case 0:
                return <></>;
            case -1:
                return <>
                    <Grid item xs={12} sm={12} md={12} lg={4}>
                        <div>
                            <p>
                                <span style={{ fontWeight: 'bold', color: 'gray', fontSize: '1.2em' }}>Next Cycle on: </span>
                                <span style={{ fontWeight: 'bold', color: 'red', fontSize: '1.2em' }}>{nextDate}</span>
                            </p>
                        </div>
                        <InputLabel id="timezone-lbl" sx={{ color: 'black', margin: '1em 0', fontSize: { md: '1.5em', lg: '1em' } }}>Time zone</InputLabel>
                        {Intl.DateTimeFormat().resolvedOptions().timeZone}
                        <InputLabel id="time-lbl" sx={{ color: 'black', margin: '1em 0', fontSize: { md: '1.5em', lg: '1em' } }}>Time</InputLabel>
                        <Select
                            labelId="time-lbl"
                            id="select-time"
                            value={repeatTime}
                            sx={{ width: '100%', height: { md: '8em', lg: '2em' } }}
                            onChange={handleTimeChange}
                        >
                            {
                                timeRanges.map((timeRange) => (

                                    <MenuItem key={timeRange} value={timeRange}><Typography variant="body2" color="textSecondary" sx={{ fontSize: { md: '2.5em', lg: '1em' } }}>{timeRange}</Typography></MenuItem>

                                ))
                            }
                        </Select>
                    </Grid>

                    <Button
                        variant="contained"
                        color="error"
                        size="large"
                        onClick={saveAssignmentData}
                        sx={{ margin: '2em 0', fontSize: { md: '1.5em', lg: '1em' }, width: { md: '100%', lg: '20%' } }}
                        disabled={submitButton}
                    >
                        Submit Answers
                    </Button>
                </>;
            case 5:
                return <>
                    <FormControl>
                        <FormLabel id={'form-label-' + questionNumber} sx={{ fontSize: { md: '2em', lg: '1em' } }}>Answers</FormLabel>
                        <br />
                        <RadioGroup
                            onChange={handleRadioChange}
                            aria-labelledby={'form-label-' + questionNumber}
                            name={'question-' + questionNumber}>
                            {
                                answers.map((answer) => (
                                    <FormControlLabel key={answer.answerCode} value={answer.answerCode} control={<Radio className={classes.radioButtonCustom} checked={activeAnswer === answer.answerCode} />} label={<StopIcon sx={{ color: answer.answer, width: { md: '20vw', lg: '10vw' }, height: { md: '20vw', lg: '10vw' }, }} />} />
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </>;
            default:
                return <>
                    <FormControl>
                        <FormLabel id={'form-label-' + questionNumber} sx={{ fontSize: { md: '1.5em', lg: '1em' } }}>Answers</FormLabel>
                        <br />
                        <RadioGroup
                            aria-labelledby={'form-label-' + questionNumber}
                            name={'question-' + questionNumber}
                            onChange={handleRadioChange}

                        >
                            {
                                answers.map((answer) => (
                                    <>
                                        <FormControlLabel key={answers.answerCode} value={answer.answerCode} control={<Radio className={classes.radioButtonCustom} sx={{ scale: '2' }} checked={activeAnswer === answer.answerCode} />} label={<Typography variant="body2" sx={{ fontSize: { md: '1.2em', lg: '1em' } }} color="textSecondary">{answer.answer}</Typography>} />
                                        <br />
                                    </>
                                ))
                            }
                        </RadioGroup>
                    </FormControl>
                </>;
        }
    }


    return (
        <>
            <form sx={{}}>
                <Box sx={{ flexGrow: 1, mt: { md: '10vh', lg: '5vh' }, fontSize: { md: '3em', lg: '1em' }, lineHeight: { md: 2, lg: 2 } }}>
                    <Paper
                        square
                        elevation={0}
                        sx={{
                            display: 'flex',
                            alignItems: 'center',
                            height: 50,
                            p: { md: '3em', lg: '1em' },
                            backgroundColor: alpha(theme.palette.background.paper, 0.5),
                        }}
                    >
                        <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>{steps[activeStep]?.label}</Typography>
                    </Paper>
                    <Box sx={{ height: 'auto', width: '100%', p: 2 }}>
                        <b>
                            <p dangerouslySetInnerHTML={{ __html: steps[activeStep]?.description }}></p>
                        </b>

                        <br /> <br />
                        {
                            selectAnswers(steps[activeStep].question, steps[activeStep].answers, activeAnswer)
                        }
                    </Box>
                    <MobileStepper
                        variant="text"
                        steps={maxSteps}
                        position="static"
                        activeStep={activeStep}
                        sx={{ backgroundColor: alpha(theme.palette.background.paper, 0.5), p: { md: '3em', lg: '1em' }, fontSize: { md: '1.5em', lg: '1em' } }}
                        nextButton={
                            <Button
                                size="small"
                                onClick={handleNext}
                                disabled={activeStep === maxSteps - 1}
                                sx={{ fontSize: { md: '1em', lg: '1em' } }}
                            >
                                <FormattedMessage id="next" />
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowLeft />
                                ) : (
                                    <KeyboardArrowRight />
                                )}
                            </Button>
                        }
                        backButton={
                            <Button size="small" onClick={handleBack} disabled={activeStep === 0} sx={{ fontSize: { md: '1em', lg: '1em' } }}>
                                {theme.direction === 'rtl' ? (
                                    <KeyboardArrowRight />
                                ) : (
                                    <KeyboardArrowLeft />
                                )}
                                <FormattedMessage id="back" />
                            </Button>
                        }
                    />
                </Box>
            </form>
        </>
    )
}

export default CustomizedContentWizardComponent