import { createContext, useState, useEffect } from 'react'
import AssignmentService from '../services/AssignmentService';


const CustomizedLessonsContext = createContext()

export const CustomizedLessonsProvider = ({ courseId, children }) => {

    const [lessonIFrame, setLessonIFrame] = useState('')
    const [lessonTitle, setLessonTitle] = useState('')
    const [lessonImage, setLessonImage] = useState('')
    const [lessonDescription, setLessonDescription] = useState('')
    const [lessonCheck, setLessonCheck] = useState(-1)
    const [lessonId, setLessonId] = useState('')
    const [responseMessage, setResponseMessage] = useState('')

    useEffect(() => {
        const checkCustomizedLessons = async () => {
            const response = await AssignmentService.checkCustomizedLessons(courseId);

            if(response?.data?.url === 0) {
                setLessonCheck(3)
            } else {
                setLessonIFrame(response?.data?.url)
                setLessonId(response?.data?.id)
                setLessonCheck(response?.data?.status)
                setResponseMessage(response?.data?.message)
                setLessonTitle(response?.data?.title)
                setLessonImage(response?.data?.image)
                setLessonDescription(response?.data?.description)
            }
        }
        
        if(lessonCheck === -1)
        {
            checkCustomizedLessons()
        }
    
    }, [lessonCheck,courseId])

    const markAsCompleteLesson = async () => {
        setLessonCheck(2)
        await AssignmentService.markAsCompleteCustomizedLessons(lessonId);
        setLessonCheck(-1)
        window.location.reload();
    }

    return <CustomizedLessonsContext.Provider
        value={{
            lessonIFrame,
            lessonCheck,
            lessonId,
            responseMessage,
            lessonTitle,
            lessonDescription,
            lessonImage,
            markAsCompleteLesson
        }}
    >
        {children}
    </CustomizedLessonsContext.Provider>
}

export default CustomizedLessonsContext