import React from 'react';



//-----------------------|| LIVE CUSTOMIZATION ||-----------------------//

const Customization = () => {


    return (
        <React.Fragment>
             {/* toggle button */}

             
           
        </React.Fragment>
    );
};

export default Customization;
