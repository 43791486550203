import React from 'react'
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import MenuItem from '@material-ui/core/MenuItem';
import { Typography } from '@material-ui/core';

const SelectComponent = ({ data, enums, updateState, initialValues }) => {

  const [initValue, setInitValue] = React.useState(initialValues?.id);
  console.log(enums);
  const changeSelectedItem = (event) => {
    setInitValue(event.target.value);
    const eventObject = {
      target: {
        name: data.id,
        value: enums[data.id].find(o => o.id === event.target.value)
      }
    };
    updateState(eventObject);
  }

  return <>
    <InputLabel id={data.id}><Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>{data.label} </Typography></InputLabel>
    <Select sx={{ fontSize: { md: '1.5em', lg: '1.25em' }, height: { md: '4em', lg: '3em' } }} size="large" labelId={data.id} value={initValue} id={data.id} label={data.label} name={data.id} onChange={changeSelectedItem}>
      {
        enums[data.id].map((enumOption) => (
          <MenuItem value={enumOption.id}>
            <Typography sx={{ fontSize: { md: '2em', lg: '1.25em' } }}>
              {enumOption.label}
            </Typography>
          </MenuItem>
        ))
      }
    </Select>
  </>

}

export default SelectComponent;
