import PropTypes from 'prop-types';
import React, { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useCookies } from 'react-cookie';
// third-party
import { IntlProvider } from 'react-intl';

// load locales files
const loadLocaleData = (locale) => {
    switch (locale) {
        case 'si':
            return import('./../utils/locales/si.json');
        default:
            return import('./../utils/locales/en.json');
    }
};

//-----------------------|| LOCALIZATION ||-----------------------//

const Locales = ({ children }) => {
    const customization = useSelector((state) => state.customization);
    const [messages, setMessages] = useState();
    const [cookies] = useCookies(['lang']);

    useEffect(() => {
        const savedLang = cookies['lang']; // Get the value of the 'lang' cookie

        // If the 'lang' cookie is set, use it as the locale, otherwise use the default locale
        const locale = savedLang || customization.locale;
        loadLocaleData(locale).then((d) => {
            setMessages(d.default);
        });
    }, [customization.locale,cookies]);

    return (
        <React.Fragment>
            {messages && (
                <IntlProvider locale={customization.locale} defaultLocale="en" messages={messages}>
                    {children}
                </IntlProvider>
            )}
        </React.Fragment>
    );
};

Locales.propTypes = {
    children: PropTypes.node
};

export default Locales;
