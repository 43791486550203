import { useContext } from 'react'
import { useCookies } from 'react-cookie';
import CustomizedContentWizardComponent from '../components/customizedContentWizard/CustomizedContentWizardComponent'
import CustomizedContentContext from '../contexts/CustomizedContentContext'
import MediaPlayer from '../components/MediaPlayer'
import Grid from '@material-ui/core/Grid';
import Paper from '@material-ui/core/Paper';

function CustomizedContentComponent() {

    const [cookies] = useCookies(['lang']);
    const { showStatus } = useContext(CustomizedContentContext);
    const lang = cookies['lang'];

    if(lang === 'si'){
        return (
            <>
                <Grid justifyContent="center" alignItems="center" style={{ display: showStatus === -1 ? 'flex' : 'none' }}>
                    <Paper variant="outlined" square sx={{ display: 'flex', padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                    ඔබගේ අභිරුචිකරණ මෙවලම් දැන් සමාලෝචනය කෙරෙමින් පවතී ...
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 4 ? 'grid' : 'none' }}>
                    <Paper variant="outlined" square sx={{ padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                        අභිරුචිකරණය කරන ලද මෙවලම් භාවිතා කිරීමට පෙර Assignments කොටසෙහි Roles of Life, Johari Window, Dream Validation, Flow State සහ Smart Goals යන කොටස් සම්පූර්ණ කොට තිබිය යුතුය. එම කොටස් ඔබට Assignments කොටසෙහි දකින්නට නොමැති නම් ඉදිරි සති කිහිපය තුල ඒවා ඔබ වෙත නිකුත් වනු ඇත. ඒවා සම්පූර්ණ කිරීමෙන් පසුව නැවත මෙම කොටස පරීක්ෂා කරන්න.
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 2 ? 'grid' : 'none' }}>
                    <Paper variant="outlined" square sx={{ padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                        ඔබ අද දිනට නියමිත අභිරුචිකරණය කරන ලද මෙවලම භාවිත කර අවසන්.හෙට නැවතත් හමුවෙමු !
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 0 ? 'grid' : 'none' }}>
                    <CustomizedContentWizardComponent />
                </Grid>
                <Grid style={{ display: showStatus === 1 ? 'grid' : 'none' }}>
                    <MediaPlayer />
                </Grid>
            </>
        )
    } else {
        return (
            <>
                <Grid justifyContent="center" alignItems="center" style={{ display: showStatus === -1 ? 'flex' : 'none' }}>
                    <Paper variant="outlined" square sx={{ display: 'flex', padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                        Checking Your Customized Content ...
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 4 ? 'grid' : 'none' }}>
                    <Paper variant="outlined" square sx={{ padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                    Before you start using your custom content, make sure you've finished all the tasks in the Assignments section and marked them as completed. If you don't see those tasks yet, don't worry, they'll be available in the next few weeks along with your lessons. After you've finished and marked them as completed, come back and check this section.
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 2 ? 'grid' : 'none' }}>
                    <Paper variant="outlined" square sx={{ padding: '3em', margin: 'auto', fontSize: { md: '2em', lg: '1.25em' }, lineHeight: '2em' }}>
                    Your daily usage of the customized content has reached its limit. This time restriction is implemented for your well-being. Rest assured, the tool will be accessible again tomorrow.
                    </Paper>
                </Grid>
                <Grid style={{ display: showStatus === 0 ? 'grid' : 'none' }}>
                    <CustomizedContentWizardComponent />
                </Grid>
                <Grid style={{ display: showStatus === 1 ? 'grid' : 'none' }}>
                    <MediaPlayer />
                </Grid>
            </>
        )
    }
}

export default CustomizedContentComponent