
import axiosServices from '../utils/axios';
import { Cookies } from 'react-cookie';

const cookies = new Cookies();

const CourseService =  {
    getUserCourses: () => {
      return axiosServices.get('/api/user-courses/',
        { 
          headers: {
            Authorization : `Bearer ${cookies.get("blToken")}`
          }
        }
      );
      
    },

};

export default CourseService;
