import React, { useState, useEffect } from 'react';
import { Typography } from '@material-ui/core';
import MainCard from '../../../ui-component/cards/MainCard';
import AddNewComponent from '../../../components/assignment/AddNewComponent';
import DetailComponent from '../../../components/assignment/DetailComponent';
import JohariWindowDetailComponent from '../../../components/assignment/JohariWindowDetailComponent';
import NormalTableComponent from '../../../components/assignment/NormalTableComponent';
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';
import Divider from '@material-ui/core/Divider';
import AssignmentService from '../../../services/AssignmentService';
import { Cookies } from 'react-cookie';
import LinearProgress from '@material-ui/core/LinearProgress';
import { AssignmentContext } from '../../../contexts/AssignmentContext';
import { useParams } from "react-router-dom";
import Snackbar from '@material-ui/core/Snackbar';
import { Alert} from '@material-ui/lab';


const useStyles = makeStyles((theme) => ({
    button: {
        margin: '0 2em'
    },
    assignmentFooter: {
        margin: '2em 0'
    }
}));

const Assignment = () => {
    const [snackBarState, setSnackBarState] = useState({});
    let { id } = useParams();
    const classes = useStyles();
    const [assignment, setAssignment] = useState([]);
    const [taskCompleted, setTaskCompleted] = useState(false);
    const [dependencies, setDependencies] = useState([]);
    const [results, setResults] = useState([]);
    const [errors, setError] = useState('');
    const [loading, setLoading] = useState(false);
    const [assignmentLoading, setAssignmentLoading] = useState(false);
    const cookies = new Cookies();
    const userId = cookies.get("blUserId");


  useEffect(() => {
      setLoading(true);
      setAssignmentLoading(false);
      AssignmentService.getAssignment(userId, id)
      .then((response) => {
          setAssignment(response?.data?.data?.taskTemplate[0]);
          setTaskCompleted(response?.data?.data?.isCompleted ?? false);
          setDependencies(response?.data?.data?.dependencies)
          formatAndSetResutls(response?.data?.data?.taskValue);
          setLoading(false);
          setAssignmentLoading(true);
      })
      .catch(() => {
          setError({
              type: 'error',
              message: 'Assignment Load Error !',
            
          })
          setLoading(false);
      })
    }, [userId, id]);
    
    const formatAndSetResutls = (result) => {
        result.forEach((element, index) => {
          element['index'] = index;
        })
        setResults(result);
    }

  
    useEffect(() => {
      formatAndSetResutls(results);
      const assignment = {
        "user": userId,
        "task": id,
        "status": true,
        "taskValue": results
    }
  
    if(assignmentLoading === true) {
      setLoading(true);
      AssignmentService.saveAssignmentData(assignment)
      .then((response) => {
          setSnackBarState({
              open: true,
              message: response?.data?.message,
              alertType: 'success'
          });
          setLoading(false);
        
          
      })
      .catch((e) => {
        console.log('error...',e)
          setSnackBarState({
              open: true,
              message:'Assignment Save Error !',
              alertType: 'error'
          });
          setLoading(false);
      });
    }

   
    }, [results, id, userId, assignmentLoading]);

    const addNewData = (data) => {
        setResults([...results, data]);
    }

    const renderDetailView = (assignment, result) => {
        switch(assignment.detailType) {
          case 'johariWindow':
            return (<JohariWindowDetailComponent  data={results} assignment={assignment} />);
          case 'normalTable':
              return (<NormalTableComponent  assignment={assignment} data={results} dependencies={dependencies}/>);
        default:
          return (<DetailComponent data={results} assignment={assignment} />);
      }
    }
  return (
    <>
      <AssignmentContext.Provider value={{assignment, results, setResults, taskCompleted}}>
        <MainCard title={<Typography sx={{ fontSize: { md: '4em', lg: '2em' }, fontWeight: 'bold' }} variant="body2">{assignment.title}</Typography>} sx={{ marginTop: { md: '8vh' } }}>
        <Box mt={2} mb={2}>
            {!!errors && (
                <Alert severity={errors.type}>{errors.message}</Alert>
            )}
             {!!loading && (
                 <>
                 <LinearProgress color="secondary"/>
                 </>
             )}
        </Box>
        <Typography variant="body2">
        <Typography
              sx={{ fontSize: { md: '2.5em', lg: '1.25em' } }}
      className={classes.root}
      variant="body1"
      dangerouslySetInnerHTML={{
        __html:
          assignment?.description
      }}
    ></Typography>
    
            <AddNewComponent data={assignment} addFunc={addNewData} dependencies={dependencies} existingData={results}/>
            <Divider />
            { renderDetailView(assignment, results) }
            <Divider />
            
        </Typography>
      </MainCard>
      </AssignmentContext.Provider>
      <Snackbar open={snackBarState.open} 
                autoHideDuration={6000} 
                onClose={() => setSnackBarState({open: false})}
                anchorOrigin={ {vertical: 'top', horizontal: 'center'}}
                >
        <Alert severity={snackBarState.alertType} sx={{ width: '100%' }} variant="filled">
          {snackBarState.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default Assignment;
