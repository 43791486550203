import _ from 'lodash';

export const validate = (assignment, data, existingData) => {
    let errors = {}

    if (assignment.title === 'Roles of Life') {

        if (!data.area) {
            errors['Area'] = 'Area field is required'
        }

        if (!data.role || data.role === "") {
            errors['Role'] = 'Role field is required'
        }
        
        if (!data.priority) {
            errors['Priority'] = 'Priority field is required'
        }
        if (data.priority < 0) {
            errors['Priority'] = 'No negative values allowed'
        }

        if(data.priority % 1 !== 0)
        {
            errors['Priority'] = 'No decimal values allowed'
        }

        if (data.priority > 60) {
            errors['Priority'] = 'Please enter a value between 1-60'
        }
        if ((_.filter(existingData, i => i.priority === data.priority && i.index !== data.index)).length > 0) {
            errors['Priority'] = 'Value already exists !'
        }
        let groupedData = _.groupBy(existingData, i => i.area?.label)
        _.map(groupedData, (v, $index) => {
            if (data?.area?.label === $index && v.length >= 10) {
                errors['Area'] = `For "${$index}" you can add only upto 10 records!`
            }
        })
    }

    if (assignment.title === 'Johari Window') {
        if (!data.question || data.question === "") {
            errors['Question'] = 'Question field is required'
        }
        if (!data.category || data.category === "") {
            errors['Category'] = 'Category field is required'
        }
        
        let groupedData = _.groupBy(existingData, i => i.category?.label)
        _.map(groupedData, (v, $index) => {
            if (data?.category?.label === $index && v.length >= 20) {
                errors['Category'] = `For "${$index}" you can add only upto 20 records!`
            }
        })
    }

    if (assignment.title === 'Dream Validation') {
        if (!data.dream || data.dream === "") {
            errors['Dream'] = 'Dream field is required'
        }
     

      

        if (!data.source || data.source === "") {
            errors['Source / Inspiration'] = 'Source / Inspiration field is required'
        }
   
   

        if (!data.reason || data.reason === "") {
            errors['Reason / Motivation'] = 'Reason / Motivation field is required'
        }
    }

    if (assignment.title === 'Flow state') {
        if (!data.activity || data.activity === "") {
            errors['Activity'] = 'Activity field is required'
        }
     
        if (!data.feeling || data.feeling === "") {
            errors['Feeling/s'] = 'Feeling/s field is required'
        }
        if (!data.innateSkill || data.innateSkill === "") {
            errors['Innate Skill?'] = 'Innate Skill field is required'
        }
        if (!data.timePassesFaster || data.timePassesFaster === "") {
            errors['Time Passes Faster?'] = 'Time Passes Faster field is required'
        }
        if (!data.demand || data.demand === "") {
            errors['The Demand'] = 'Demand field is required'
        }
    }

    if (assignment.title === 'Smart Goals') {
        if (!data.goal || data.goal === "") {
            errors['SMRT Goal'] = 'Goal field is required'
        }
     
        if (!data.role || data.role === "") {
            errors['Role'] = 'Role field is required'
        }
    }

    return errors;
}