exports.stepsSI = [
    {
        question: 0,
        label: `Instructions`,
        description: `<p>අතික්‍රමණ පාඨමාලාවෙන් ඔබ වෙත ලබා දෙන අභිරුචිකරණය කරන ලද (customized) මෙවලම් වලින් එකක් ලෙස මෙම මෙවලම අප සකසා ඇත. මෙම ක්‍රියාකාරමක නූතන විද්‍යාව හා පෙරදිග විද්‍යාව යන දැනුම් පද්ධති දෙකේම සංයෝජනයකි (fusion). ඒ සංයෝජනය සිදු කොට ඇත්තේ කෙසේද යන්න සහ එය ඔබට ගැලපෙන පරිදි සුසර කොට ඇත්තේ කෙසේද යන්න පහත දැක්වේ.</p>
    
        <p>ද්විකර්ණික ස්පන්ධ (binaural beats) යනු නූතන විද්‍යාත්මක පසුබිමක් මත නිර්මාණය වී පසු කලෙක බටහිර සමාජයන්හි ප්‍රචලිත වූ සංකල්පයකි. දකුණු කන සහ වම් කන වෙත වෙනස් සංඛ්‍යාතයන් දෙකක් ලබා දීමෙන් මොළයේ සංඛ්‍යාතයන් වෙනස් කිරීමට හැකි බව නිරීක්ෂණය කිරීම නිසා මෙය බටහිර ලෝකයේ ප්‍රචලිත විය. මොළයේ මෙම තරංග මනින්නේ විද්‍යුත් නිකර්පරරේඛ (EEG) භාවිතා කරමිනි.</p>
        
        <p>කෙසේ නමුදු දකුණු හා වම් කනට ලබා දිය යුතු සංඛ්‍යාතයන් තෝරා ගැනීමේදී හා ඒ සංඛ්‍යාතයන්ට අනුව මොළයේ වන වෙනස් කිරීම නිරීක්ෂණය කිරීමේදී එම වෙනස් කම් ඩෙල්ටා, තේටා, ඇල්ෆා, බේටා හා ගැමා  පොදු තරංග  වර්ග කිහිපයකට පමණක් නූතන විද්‍යාවේ කාණ්ඩ කොට ඇත. මෙය EEG තාක්ෂණයේ ඇති යම් සීමාවන් නිසාද සිදු විය හැක.</p>
        
        <p>නමුත්  අප  සමඟ කටයතු කරන මනස දියුණු කරන ලද පෙරදිග විද්‍යාධරයින් දකුණු කනට හා වම් කනට ඇසෙන තරංග රටාවන් වෙනස් වන විට ඒවාට අනුරූපව වඩා විශාල ලෙස සියුම් වෙනස් කම් මනසට හා මොළයට සිදුවන බව තම ඥාණය ආධාරයෙන් නිරීක්ෂණය කරන ලදි.  ඒ ඒ තරංග රටා අනුව මනසේ විවිධ සියුම් කොටස් දියුණුවට ලක් කල හැකි බව ඔවුන් කරන ලද නිරීක්ෂණයයි.</p>
        
         
        
        <p>ඒ අනුව මනසේ විවිධ කොටස් දියුණු කිරීමට නම් ඊට අදාලව දකුණු හා වම් කණට සැපයිය යුතු විවිධාකාර වෙනස් තරංග රටා හඳුණා ගැනීමක් සහ ඒ සම්බන්ධ ආකෘතියක් නිර්මාණය කිරීම අප අතින් සිදු විය. මෙම දැනුම සහිතව එම විද්‍යාධරයින්ගේ උදව් ඇතිව මෙම ප්‍රචලිත දිවිකර්ණික ස්පන්ධ තාක්ෂණය වඩාත් සුසර කොට, ඔබගේ ජීවිතයට වඩාත් උචිත ලෙස භාවිතා කිරීමට හැකි ආකාරයේ ක්‍රමයක් අප නිර්මාණය කොට ඇත.</p>
        
        <p>ඔබ Brainlab හි ක්‍රියාකාරකම් වලට ඇතුලත් කොට ඇති දත්ත අනුව හා ඔබට මේ මොහොතේ දැනෙන දේවල් කිහිපයක් ආදාන (inputs) ලෙස භාවිතා කොට ඔබට අභිරුචිකරණය වූ ඔබගේ තෝරාගන්නා ලද ජීවිත ගමනට අවශ්‍ය මනසේ කොටස් දියුණු කර ගැනීමට අති ප්‍රබල මෙවලමක් මෙයින් සපයා දීමට අප බලාපොරොත්තු වෙමු.</p>
        
        
        <p>ඔබගේ  Brainlab ගිණුම හරහා වෙනත් කිසිම පුද්ගලයෙකුට මෙය භාවිතා කිරීමට ලබා නොදෙන්න. මන්ද මෙම තරංග රටා ඔබට ආවේණික ලෙස නිර්මාණය වන නිසාය. මෙය පුද්ගලයාගෙන් පුද්ගලයාට ඒ අය තෝරාගෙන ඇති ජීවිතයේ දිශානතිය, ඒ අයගේ ගුණාංග, ඉලක්ක ආදිය අනුව සම්පූර්ණයෙන් වෙනස් වන අතර එකම පුද්ගලයාට පවා දින දෙකකදී ලැබෙන තරංග රටා වෙනස් විය හැක. මන්ද භාවිතා කරන්නා තුල භාවිතා කරන දිනයේදී ඇති වන වෙනස්කම්ද සැලකිල්ලට ගන්නා නිසාය.</p>
        
         
        
        <p>භාවිතාකිරීමට උපදෙස්</p>
        
        <p> 1. මෙම මෙවලම භාවිතා කිරීමට නම් ඔබ Brainlab එක තුල සියළුම ක්‍රියාකාරකම් සම්පූර්ණ කොට තිබිය යුතුය. එසේ නොමැති නම් මෙයට පිවිසුම් නොලැබෙනු ඇත. මන්ද එම දත්ත මෙම ක්‍රියාකාරකමට අවශ්‍ය නිසාය.</p>
        
        <p>2. මෙම මෙවලව භාවිතා කිරීමට කිරීමට කන් බණුවක් (earphones) අත්‍යාවශ්‍යය. කන් බණුවක් භාවිතා නොකර මෙම මෙවලම භාවිතා  කිරීමෙන් කිසිම ඵලක් නැත.</p>
        
        <p>3. ඔබට මේ මොහොතේ දැනෙන දේවල් කිහිපයක් පිළිබඳව මීලඟ screens වලදී ප්‍රශ්ණ කිහිපයක් අසනු ලබයි. ඒවාට ඉතා අවංකව පිළිතුරු සපයන්න. </p>
        
         
        
        <p> 4. ඉන්පසු  ඉතා ආවේණික තරංග රටාවක් සහිත සංගීත ඛණ්ඩයක් කන් බණුව (earphones) හරහා ඔබට ඇසීමට හැකිය. උඩින් ඇසෙන මියුරු සංගීතයට යටින් අති ප්‍රබල තරංග රටා කිහිපයක් ක්‍රියාත්මක වේ.</p>
        <p> 5. මෙම මෙවලම භාවිතා කල හැක්කේ දිනෙකට විනාඩි 30 පමණි.</p>
        <p> 6. ඔබ කාර්යභහුල කෙනෙක් නම් වෙනත් වැඩක් කරන අතර වුවද මෙයට සවන් දිය හැකිය. කැමති නම් දෑස් පියාගෙන දිගා වී, හිඳගෙන හෝ, සවන් දීමද සිදු කල හැක. </p>
        <p> 7. මෙම මෙවලම නිසා දිනපතා කිරීමට ලබා දී ඇති භාවනාව නවත්වන්නට එපා. මෙය ඒ වෙනුවට ආදේශකයක් (substitute) නොවේ.</p> 
        <p> 8. මෙයට සවන් දෙන විට යම් අපහසුතාවයක්, හිසරදයක් වැන්නක් ඇති වූවොත් වහාම ක්‍රියාකාරමක නවත්වා support@braincipher.atlassian.net වෙත ඊමේල් එකක් එවන්න.</p>
        `,
        answers: []
    },
    {
        question: 1,
        label: `Question 01`,
        description: `මේ මොහොතේ ඔබගේ ජීවිතය පිළිබඳව සමස්තයක් ලෙස 
          අද ඔබට දැනෙන ආකාරය කෙබඳුද?
           ( මෙය දිනපතා හෝ මොහොතින් මොහොත වෙනස් වන හැඟීමකි. 
          මේ මොහොතේ දැනෙන ආකාරය ගැන පමණක් හිතන්න)`,
        answers: [
            {
                answer: `මා අතිශයින් සාර්ථක පුද්ගලයෙකු බව සිතේ`,
                answerCode: 'Q1_0',
                value: '5'
            },
            {
                answer: `මා යම් තරමකින් සාර්ථක පුද්ගලයෙකු බව මට සිතේ`,
                answerCode: 'Q1_1',
                value: '3'
            },
            {
                answer: `මා සාර්ථක නැති අසාර්ථකත් නැති පුද්ගලයෙකු බව මට සිතේ`,
                answerCode: 'Q1_2',
                value: '1'
            },
            {
                answer: `මා අසාර්ථක පුද්ගලයෙකි`,
                answerCode: 'Q1_3',
                value: '-3'
            },
            {
                answer: `මා අතිශයින් අසාර්ථක පුද්ගලයෙකි`,
                answerCode: 'Q1_4',
                value: '-5'
            },
        ]
    },
    {
        question: 2,
        label: 'Question 02',
        description:
            `<p>අද දවසේ මෙතක් වේලා ඔබ විසින් ඉටු කොට අවසන් කල වගකීම් හා ක්‍රියාවලීන් තිබේ නම් ඒ ගැන ඔබට සිතෙන්නේ කෙසේද (මේ මොහොතේ සිතෙන ආකාරය) ? </p>
    
           <p>එසේත් නැත්නම් ඔබ මේ මෙවලම භාවිතා කිරීමට සූදානම් වන්නේ අද දිනය ආරම්භ වන මොහොතේ නම්, ඊයේ දිනයේදී ඔබ විසින් ඉටු කොට අවසන් කල වගකීම් හා ක්‍රියාවලීන් ගැන ඔබට සිතෙන්නේ කෙසේද (මේ මොහොතේ සිතෙන ආකාරය) ?</p>`,
        answers: [
            {
                answer: `මා කල යුතු දේ හොඳින් ඉටු කලා ඉතාම තෘප්තිමත්`,
                answerCode: 'Q2_0',
                value: '5'
            },
            {
                answer: `මා කල යුතු දේ යම් මට්ටමකින් ඉටු කලා තරමක් තෘප්තිමත්`,
                answerCode: 'Q2_1',
                value: '4'
            },
            {
                answer: `වෙනත් නොවැලක්විය හැකි හේතූන් නිසා ඒවා නිවැරදි පරිදි ඉටු කරගන්න බැරි වුනා. නමුත් කණගාටුවක් නෑ`,
                answerCode: 'Q2_2',
                value: '3'
            },
            {
                answer: `වෙනත් නොවැලක්විය හැකි හේතූන් නිසා ඒවා නිවැරදි පරිදි ඉටු කරගන්න බැරි වුනා. ඒ නිසා කුඩා කණගාටුවක් තිබෙනවා`,
                answerCode: 'Q2_3',
                value: '2'
            },
            {
                answer: `මා කල යුතු බොහෝ දේ මා කලේ නැහැ. නමුත් මට වරදකාරී හැඟීමක් දැනෙන්නේ නෑ.`,
                answerCode: 'Q2_4',
                value: '1'
            },
            {
                answer: `මා කල යුතු බොහෝ දේ මා කලේ නැහැ. වරදකාරී හැඟීමක් දැනෙනවා.`,
                answerCode: 'Q2_5',
                value: '-1'
            },
            {
                answer: `මා කල යුතු කිසිම දෙයක් මා කලේ නෑ. නමුත් මට වරදකාරී හැඟීමක් දැනෙන්නේ නෑ.`,
                answerCode: 'Q2_6',
                value: '-2'
            },
            {
                answer: `මා කල යුතු කිසිම දෙයක් මා කලේ නෑ. මට විශාල වරදකාරී හැඟීමක් දැනෙනවා`,
                answerCode: 'Q2_7',
                value: '-5'
            },
        ]
    },
    {
        question: 3,
        label: 'Question 03',
        description:
            `<p>අද දවසේ ඉදිරියට ඔබ විසින් ඉටු කල යුතු වගකීම් හා ක්‍රියාවලීන්  ගැන ඔබට සිතෙන්නේ කෙසේද ( මේ මොහොතේ සිතෙන ආකාරය) ?</p>
    
             <p>එසේත් නැත්නම් ඔබ මෙම මෙවලම භාවිතා කිරීමට සැරසෙන්නේ දවස අවසානයේ නම්, හෙට දින  ඔබ විසින් ඉටු කල යුතු වගකීම් හා ක්‍රියාවලීන්  ගැන ඔබට සිතෙන්නේ කෙසේද ( මේ මොහොතේ සිතෙන ආකාරය) ?</p>`,
        answers: [
            {
                answer: `මට ඒ පිළිබඳව ඇත්තේ අතිශය ධනාත්මක සිතිවිල්ලක්`,
                answerCode: 'Q3_0',
                value: '5'
            },
            {
                answer: `මට ඒ පිළිබඳව ඇත්තේ යම් තරමක් ධනාත්මක සිතිවිල්ලක්`,
                answerCode: 'Q3_1',
                value: '3'
            },
            {
                answer: `මට ඒ පිළිබඳව ඇත්තේ ධනාත්මකත් නොවන සෘණාත්මකත් නොවන උපේක්ෂා සහගත සිතිවිල්ලක්.`,
                answerCode: 'Q3_2',
                value: '1'
            },
            {
                answer: `මට ඒ පිළිබඳව ඇත්තේ  තරමක් සෘණාත්මක සිතිවිල්ලක්`,
                answerCode: 'Q3_3',
                value: '-3'
            },
            {
                answer: `මට ඒවා දැනෙන්නේ වධයක් ලෙස`,
                answerCode: 'Q3_4',
                value: '-5'
            },
        ]
    },
    {
        question: 4,
        label: 'Question 04',
        description:
            `මේ මොහොතේ ඔබට ඇති ධෛර්යය කෙබඳුද?`,
        answers: [
            {
                answer: `මට කඳු, හෙල්, පර්වත වුවද පෙරලා දැමිය හැකි යැයි සිතේ`,
                answerCode: 'Q4_0',
                value: '5'
            },
            {
                answer: `මට සාමාන්‍ය ප්‍රමාණයේ ධෛර්යයක් ඇත`,
                answerCode: 'Q4_1',
                value: '3'
            },
            {
                answer: `මට යාන්තම් වැඩ ටික කර ගැනීමට අවශ්‍යය ධෛර්යය තිබේ`,
                answerCode: 'Q4_2',
                value: '1'
            },
            {
                answer: `මට කිසිම ධෛර්යයක් නැත`,
                answerCode: 'Q4_3',
                value: '-3'
            },
            {
                answer: `මට පොළොවේ ගුලක් හාරා, රිංගා සැඟවී සිටීමට සිතේ`,
                answerCode: 'Q4_4',
                value: '-5'
            },
        ]
    },
    {
        question: 5,
        label: 'Question 05',
        description: `පහත වර්ණ වලින් මේ මොහොතේ ඔබට වඩාත්ම පියකරු ලෙස පෙනෙන්නේ 
          ( ලස්සනට පෙනෙන්නේ) කුමන වර්ණයද? 
          ( මෙය වෙනස් විය හැක. මේ මොහොතේ පියකරු වර්ණය පමණක් තෝරන්න)`,
        answers: [
            {
                answer: `#B53630`,
                answerCode: 'Q5_0',
                value: '-7'
            },
            {
                answer: `#D96533`,
                answerCode: 'Q5_1',
                value: '-6'
            },
            {
                answer: `#E4AD38`,
                answerCode: 'Q5_2',
                value: '-5'
            },
            {
                answer: `#88C152`,
                answerCode: 'Q5_3',
                value: '-5'
            },
            {
                answer: `#48AFE3`,
                answerCode: 'Q5_4',
                value: '-6'
            },
            {
                answer: `#5D4D98`,
                answerCode: 'Q5_5',
                value: '-7'
            },
            {
                answer: `#F5F5F5`,
                answerCode: 'Q5_6',
                value: '-9'
            },

        ]
    },
    {
        question: -1,
        label: 'Completion',
        description: `අවසාන වශයෙන් ඔබට මෙම මෙවලම නැවත භාවිත කිරීමට අවශ්‍ය වේලා කලාපය (Time zone) හා වෙලාව දක්වන්න. එවිට ඔබගේ  දෛනිකව ඔබට අදාළ අභිරුචිකරන මෙවලම ඔබට අදාළ වෙලාවට සක්‍රිය වනු ඇත.`,
        answers: []
    },
];

exports.stepsEN = [
    {
        question: 0,
        label: `Instructions`,
        description: `<p><b>Overview:</b></p>
        <ul>
        <li>Our tool is a bespoke brainwave technology, devised from the fusion of contemporary scientific understanding and insights from long-term meditators.</li>
        <li>It uses your personal data to dynamically adjust frequencies, catering to your current circumstances, roles, and objectives.</li>
        <li>Proper functionality requires the use of earphones, earbuds, or headphones; any stereo compatible audio device will suffice.</li>
        <li>To ensure safety and efficacy, usage should not exceed 30 minutes per day.</li>
        <li>It is crucial to prevent others from using your account, as the frequencies are specifically tailored to you.</li>
        </ul>
        <p>&nbsp;</p>
        <p><b>Introduction:</b></p>
        <p>This tool, one of the primary resources available to you as a student, represents a synthesis of Eastern and Western knowledge bases. Binaural beats, revered in the West for their demonstrated capacity to positively influence human moods, emotions, memory, and cognition (Petrovich, 2018; Vedovo et al, 2023), are a key feature of this tool.</p>
        <p>Binaural beats are an auditory stimulus that presents two distinct tones to each ear, producing a perceived third tone that induces an altered state of brainwaves. Contemporary science has identified five main states: Gamma, Beta, Alpha, Theta, and Delta.</p>
        <p>We sought to further refine this model by consulting experienced meditators and yogis. These experts, possessing profound insights into the human mind, aided in the development of a formula that allows the frequencies of the binaural beats to be dynamically adjusted based on various individual data points.</p>
        <p>This enhancement has culminated in our Customized Brain Wave Tool. Let us guide you through some crucial instructions for its proper use.</p>
        <p><br /><br /></p>
        <p><b>Use of Equipment:</b></p>
        <p>In order to reap the full benefits of this tool, it's essential that you use earphones, earbuds, or headphones. These act as the conduit between the tool and your brain, allowing for the accurate and efficient transmission of the customized frequencies. Any kind of earphone can be used, provided it's compatible with the device running the tool.</p>
        <p>&nbsp;</p>
        <p><b>Usage Guidelines:</b></p>
        <p>While this tool is designed to optimize your brain function, it's essential to use it responsibly. Overuse could lead to undesirable outcomes. For this reason, it is recommended that you limit your usage to no more than 30 minutes per day. This duration is carefully calculated to provide the maximum benefit without causing undue strain on your brain.</p>
        <p>While engaging in a range of activities such as reading, working, or even light exercise, you can benefit from these brainwaves. However, for your safety, we strongly recommend avoiding the use of this tool during activities that demand your full attention, such as driving.</p>
        <p>&nbsp;</p>
        <p><b>Personalized Experience:</b></p>
        <p>The brainwave tool generates a unique profile based on individual data. Therefore, it's crucial to remember that these profiles are exclusive to each user. Allowing others to listen through your account could lead to an ineffective, or potentially disruptive, experience for them as the frequencies are specifically tailored for you.</p>
        <p>Overall, this unique brainwave tool stands at the intersection of technology and human potential, offering a personalized path to enhanced brain function and well-being. Use it wisely and enjoy the benefits of a more harmonious and focused mind.</p>
        <p>Should you encounter any technical issues with this tool, please reach out to us at tech@iini.zendesk.com for assistance.</p>
        <p>Should you experience any health-related concerns such as headaches while using this tool, immediately cease its use and contact our general support team at generalsupport@iini.zendesk.com.</p>`,
        answers: []
    },
    {
        question: 1,
        label: `Question 01`,
        description: `How do you currently view your life?`,
        answers: [
            {
                answer: `I see myself as successful.`,
                answerCode: 'Q1_0',
                value: '5'
            },
            {
                answer: `I think I'm somewhat successful.`,
                answerCode: 'Q1_1',
                value: '3'
            },
            {
                answer: `I don't feel particularly successful or unsuccessful.`,
                answerCode: 'Q1_2',
                value: '1'
            },
            {
                answer: `I see myself as unsuccessful.`,
                answerCode: 'Q1_3',
                value: '-3'
            },
            {
                answer: `I believe I'm a complete failure.`,
                answerCode: 'Q1_4',
                value: '-5'
            },
        ]
    },
    {
        question: 2,
        label: 'Question 02',
        description:
            `<p>Thinking about the tasks you had to do today (or yesterday if it's early morning), how do you feel about your progress?</p>`,
        answers: [
            {
                answer: `I finished all my tasks and feel accomplished.`,
                answerCode: 'Q2_0',
                value: '5'
            },
            {
                answer: `I finished some tasks and feel somewhat accomplished.`,
                answerCode: 'Q2_1',
                value: '4'
            },
            {
                answer: `I couldn't do my planned activities due to unavoidable reasons, and I'm okay with that.`,
                answerCode: 'Q2_2',
                value: '3'
            },
            {
                answer: `I couldn't do my planned activities due to unavoidable reasons, and I feel bad.`,
                answerCode: 'Q2_3',
                value: '2'
            },
            {
                answer: `I didn't do some of the things I had to do, I chose not to and I don't feel guilty.`,
                answerCode: 'Q2_4',
                value: '1'
            },
            {
                answer: `I didn't do some of the things I had to do, I chose not to, and I do feel guilty.`,
                answerCode: 'Q2_5',
                value: '-1'
            },
            {
                answer: `I didn't do any of the things I had to do, I chose not to and I don't feel guilty.`,
                answerCode: 'Q2_6',
                value: '-2'
            },
            {
                answer: `I didn't do any of the things I had to do, I chose not to and I do feel guilty.`,
                answerCode: 'Q2_7',
                value: '-5'
            },
        ]
    },
    {
        question: 3,
        label: 'Question 03',
        description:
            `<p>How do you feel about the tasks you need to do today?</p>`,
        answers: [
            {
                answer: `I feel extremely positive.`,
                answerCode: 'Q3_0',
                value: '5'
            },
            {
                answer: `I feel positive.`,
                answerCode: 'Q3_1',
                value: '3'
            },
            {
                answer: `I feel neutral. I'll do what needs to be done.`,
                answerCode: 'Q3_2',
                value: '1'
            },
            {
                answer: `I feel somewhat negative.`,
                answerCode: 'Q3_3',
                value: '-3'
            },
            {
                answer: `I feel as if it's a huge burden.`,
                answerCode: 'Q3_4',
                value: '-5'
            },
        ]
    },
    {
        question: 4,
        label: 'Question 04',
        description:
            `How would you describe your current motivation level?`,
        answers: [
            {
                answer: `I feel incredibly motivated!`,
                answerCode: 'Q4_0',
                value: '5'
            },
            {
                answer: `I feel somewhat motivated.`,
                answerCode: 'Q4_1',
                value: '3'
            },
            {
                answer: `I'm barely motivated enough to get my tasks done.`,
                answerCode: 'Q4_2',
                value: '1'
            },
            {
                answer: `I'm not motivated to do my tasks.`,
                answerCode: 'Q4_3',
                value: '-3'
            },
            {
                answer: `I feel so unmotivated, I'd rather hide away.`,
                answerCode: 'Q4_4',
                value: '-5'
            },
        ]
    },
    {
        question: 5,
        label: 'Question 05',
        description: `Which color is most appealing to you right now? (If all colors seem the same, just pick one at random.)`,
        answers: [
            {
                answer: `#B53630`,
                answerCode: 'Q5_0',
                value: '-7'
            },
            {
                answer: `#D96533`,
                answerCode: 'Q5_1',
                value: '-6'
            },
            {
                answer: `#E4AD38`,
                answerCode: 'Q5_2',
                value: '-5'
            },
            {
                answer: `#88C152`,
                answerCode: 'Q5_3',
                value: '-5'
            },
            {
                answer: `#48AFE3`,
                answerCode: 'Q5_4',
                value: '-6'
            },
            {
                answer: `#5D4D98`,
                answerCode: 'Q5_5',
                value: '-7'
            },
            {
                answer: `#F5F5F5`,
                answerCode: 'Q5_6',
                value: '-9'
            },

        ]
    },
    {
        question: -1,
        label: 'Completion',
        description: `Please provide the specific time and timezone at which you would like to activate this tool on a daily basis.`,
        answers: []
    },
];

exports.timeZones = [
    "Africa/Abidjan",
    "Africa/Accra",
    "Africa/Addis_Ababa",
    "Africa/Algiers",
    "Africa/Asmara",
    "Africa/Bamako",
    "Africa/Bangui",
    "Africa/Banjul",
    "Africa/Bissau",
    "Africa/Blantyre",
    "Africa/Brazzaville",
    "Africa/Bujumbura",
    "Africa/Cairo",
    "Africa/Casablanca",
    "Africa/Ceuta",
    "Africa/Conakry",
    "Africa/Dakar",
    "Africa/Dar_es_Salaam",
    "Africa/Djibouti",
    "Africa/Douala",
    "Africa/El_Aaiun",
    "Africa/Freetown",
    "Africa/Gaborone",
    "Africa/Harare",
    "Africa/Johannesburg",
    "Africa/Juba",
    "Africa/Kampala",
    "Africa/Khartoum",
    "Africa/Kigali",
    "Africa/Kinshasa",
    "Africa/Lagos",
    "Africa/Libreville",
    "Africa/Lome",
    "Africa/Luanda",
    "Africa/Lubumbashi",
    "Africa/Lusaka",
    "Africa/Malabo",
    "Africa/Maputo",
    "Africa/Maseru",
    "Africa/Mbabane",
    "Africa/Mogadishu",
    "Africa/Monrovia",
    "Africa/Nairobi",
    "Africa/Ndjamena",
    "Africa/Niamey",
    "Africa/Nouakchott",
    "Africa/Ouagadougou",
    "Africa/Porto-Novo",
    "Africa/Sao_Tome",
    "Africa/Tripoli",
    "Africa/Tunis",
    "Africa/Windhoek",
    "America/Adak",
    "America/Anchorage",
    "America/Anguilla",
    "America/Antigua",
    "America/Araguaina",
    "America/Argentina/Buenos_Aires",
    "America/Argentina/Catamarca",
    "America/Argentina/Cordoba",
    "America/Argentina/Jujuy",
    "America/Argentina/La_Rioja",
    "America/Argentina/Mendoza",
    "America/Argentina/Rio_Gallegos",
    "America/Argentina/Salta",
    "America/Argentina/San_Juan",
    "America/Argentina/San_Luis",
    "America/Argentina/Tucuman",
    "America/Argentina/Ushuaia",
    "America/Aruba",
    "America/Asuncion",
    "America/Atikokan",
    "America/Bahia",
    "America/Bahia_Banderas",
    "America/Barbados",
    "America/Belem",
    "America/Belize",
    "America/Blanc-Sablon",
    "America/Boa_Vista",
    "America/Bogota",
    "America/Boise",
    "America/Cambridge_Bay",
    "America/Campo_Grande",
    "America/Cancun",
    "America/Caracas",
    "America/Cayenne",
    "America/Cayman",
    "America/Chicago",
    "America/Chihuahua",
    "America/Costa_Rica",
    "America/Creston",
    "America/Cuiaba",
    "America/Curacao",
    "America/Danmarkshavn",
    "America/Dawson",
    "America/Dawson_Creek",
    "America/Denver",
    "America/Detroit",
    "America/Dominica",
    "America/Edmonton",
    "America/Eirunepe",
    "America/El_Salvador",
    "America/Fort_Nelson",
    "America/Fortaleza",
    "America/Glace_Bay",
    "America/Goose_Bay",
    "America/Grand_Turk",
    "America/Grenada",
    "America/Guadeloupe",
    "America/Guatemala",
    "America/Guayaquil",
    "America/Guyana",
    "America/Halifax",
    "America/Havana",
    "America/Hermosillo",
    "America/Indiana/Indianapolis",
    "America/Indiana/Knox",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Tell_City",
    "America/Indiana/Vevay",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Inuvik",
    "America/Iqaluit",
    "America/Jamaica",
    "America/Juneau",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Kralendijk",
    "America/La_Paz",
    "America/Lima",
    "America/Los_Angeles",
    "America/Lower_Princes",
    "America/Maceio",
    "America/Managua",
    "America/Manaus",
    "America/Marigot",
    "America/Martinique",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Menominee",
    "America/Merida",
    "America/Metlakatla",
    "America/Mexico_City",
    "America/Miquelon",
    "America/Moncton",
    "America/Monterrey",
    "America/Montevideo",
    "America/Montserrat",
    "America/Nassau",
    "America/New_York",
    "America/Nipigon",
    "America/Nome",
    "America/Noronha",
    "America/North_Dakota/Beulah",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/Nuuk",
    "America/Ojinaga",
    "America/Panama",
    "America/Pangnirtung",
    "America/Paramaribo",
    "America/Phoenix",
    "America/Port-au-Prince",
    "America/Port_of_Spain",
    "America/Porto_Velho",
    "America/Puerto_Rico",
    "America/Punta_Arenas",
    "America/Rainy_River",
    "America/Rankin_Inlet",
    "America/Recife",
    "America/Regina",
    "America/Resolute",
    "America/Rio_Branco",
    "America/Santarem",
    "America/Santiago",
    "America/Santo_Domingo",
    "America/Sao_Paulo",
    "America/Scoresbysund",
    "America/Sitka",
    "America/St_Barthelemy",
    "America/St_Johns",
    "America/St_Kitts",
    "America/St_Lucia",
    "America/St_Thomas",
    "America/St_Vincent",
    "America/Swift_Current",
    "America/Tegucigalpa",
    "America/Thule",
    "America/Thunder_Bay",
    "America/Tijuana",
    "America/Toronto",
    "America/Tortola",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Winnipeg",
    "America/Yakutat",
    "America/Yellowknife",
    "Antarctica/Casey",
    "Antarctica/Davis",
    "Antarctica/DumontDUrville",
    "Antarctica/Macquarie",
    "Antarctica/Mawson",
    "Antarctica/McMurdo",
    "Antarctica/Palmer",
    "Antarctica/Rothera",
    "Antarctica/Syowa",
    "Antarctica/Troll",
    "Antarctica/Vostok",
    "Arctic/Longyearbyen",
    "Asia/Aden",
    "Asia/Almaty",
    "Asia/Amman",
    "Asia/Anadyr",
    "Asia/Aqtau",
    "Asia/Aqtobe",
    "Asia/Ashgabat",
    "Asia/Atyrau",
    "Asia/Baghdad",
    "Asia/Bahrain",
    "Asia/Baku",
    "Asia/Bangkok",
    "Asia/Barnaul",
    "Asia/Beirut",
    "Asia/Bishkek",
    "Asia/Brunei",
    "Asia/Chita",
    "Asia/Choibalsan",
    "Asia/Colombo",
    "Asia/Damascus",
    "Asia/Dhaka",
    "Asia/Dili",
    "Asia/Dubai",
    "Asia/Dushanbe",
    "Asia/Famagusta",
    "Asia/Gaza",
    "Asia/Hebron",
    "Asia/Ho_Chi_Minh",
    "Asia/Hong_Kong",
    "Asia/Hovd",
    "Asia/Irkutsk",
    "Asia/Jakarta",
    "Asia/Jayapura",
    "Asia/Jerusalem",
    "Asia/Kabul",
    "Asia/Kamchatka",
    "Asia/Karachi",
    "Asia/Kathmandu",
    "Asia/Khandyga",
    "Asia/Kolkata",
    "Asia/Krasnoyarsk",
    "Asia/Kuala_Lumpur",
    "Asia/Kuching",
    "Asia/Kuwait",
    "Asia/Macau",
    "Asia/Magadan",
    "Asia/Makassar",
    "Asia/Manila",
    "Asia/Muscat",
    "Asia/Nicosia",
    "Asia/Novokuznetsk",
    "Asia/Novosibirsk",
    "Asia/Omsk",
    "Asia/Oral",
    "Asia/Phnom_Penh",
    "Asia/Pontianak",
    "Asia/Pyongyang",
    "Asia/Qatar",
    "Asia/Qostanay",
    "Asia/Qyzylorda",
    "Asia/Riyadh",
    "Asia/Sakhalin",
    "Asia/Samarkand",
    "Asia/Seoul",
    "Asia/Shanghai",
    "Asia/Singapore",
    "Asia/Srednekolymsk",
    "Asia/Taipei",
    "Asia/Tashkent",
    "Asia/Tbilisi",
    "Asia/Tehran",
    "Asia/Thimphu",
    "Asia/Tokyo",
    "Asia/Tomsk",
    "Asia/Ulaanbaatar",
    "Asia/Urumqi",
    "Asia/Ust-Nera",
    "Asia/Vientiane",
    "Asia/Vladivostok",
    "Asia/Yakutsk",
    "Asia/Yangon",
    "Asia/Yekaterinburg",
    "Asia/Yerevan",
    "Atlantic/Azores",
    "Atlantic/Bermuda",
    "Atlantic/Canary",
    "Atlantic/Cape_Verde",
    "Atlantic/Faroe",
    "Atlantic/Madeira",
    "Atlantic/Reykjavik",
    "Atlantic/South_Georgia",
    "Atlantic/St_Helena",
    "Atlantic/Stanley",
    "Australia/Adelaide",
    "Australia/Brisbane",
    "Australia/Broken_Hill",
    "Australia/Darwin",
    "Australia/Eucla",
    "Australia/Hobart",
    "Australia/Lindeman",
    "Australia/Lord_Howe",
    "Australia/Melbourne",
    "Australia/Perth",
    "Australia/Sydney",
    "Europe/Amsterdam",
    "Europe/Andorra",
    "Europe/Astrakhan",
    "Europe/Athens",
    "Europe/Belgrade",
    "Europe/Berlin",
    "Europe/Bratislava",
    "Europe/Brussels",
    "Europe/Bucharest",
    "Europe/Budapest",
    "Europe/Busingen",
    "Europe/Chisinau",
    "Europe/Copenhagen",
    "Europe/Dublin",
    "Europe/Gibraltar",
    "Europe/Guernsey",
    "Europe/Helsinki",
    "Europe/Isle_of_Man",
    "Europe/Istanbul",
    "Europe/Jersey",
    "Europe/Kaliningrad",
    "Europe/Kiev",
    "Europe/Kirov",
    "Europe/Lisbon",
    "Europe/Ljubljana",
    "Europe/London",
    "Europe/Luxembourg",
    "Europe/Madrid",
    "Europe/Malta",
    "Europe/Mariehamn",
    "Europe/Minsk",
    "Europe/Monaco",
    "Europe/Moscow",
    "Europe/Oslo",
    "Europe/Paris",
    "Europe/Podgorica",
    "Europe/Prague",
    "Europe/Riga",
    "Europe/Rome",
    "Europe/Samara",
    "Europe/San_Marino",
    "Europe/Sarajevo",
    "Europe/Saratov",
    "Europe/Simferopol",
    "Europe/Skopje",
    "Europe/Sofia",
    "Europe/Stockholm",
    "Europe/Tallinn",
    "Europe/Tirane",
    "Europe/Ulyanovsk",
    "Europe/Uzhgorod",
    "Europe/Vaduz",
    "Europe/Vatican",
    "Europe/Vienna",
    "Europe/Vilnius",
    "Europe/Volgograd",
    "Europe/Warsaw",
    "Europe/Zagreb",
    "Europe/Zaporozhye",
    "Europe/Zurich",
    "Indian/Antananarivo",
    "Indian/Chagos",
    "Indian/Christmas",
    "Indian/Cocos",
    "Indian/Comoro",
    "Indian/Kerguelen",
    "Indian/Mahe",
    "Indian/Maldives",
    "Indian/Mauritius",
    "Indian/Mayotte",
    "Indian/Reunion",
    "Pacific/Apia",
    "Pacific/Auckland",
    "Pacific/Bougainville",
    "Pacific/Chatham",
    "Pacific/Chuuk",
    "Pacific/Easter",
    "Pacific/Efate",
    "Pacific/Fakaofo",
    "Pacific/Fiji",
    "Pacific/Funafuti",
    "Pacific/Galapagos",
    "Pacific/Gambier",
    "Pacific/Guadalcanal",
    "Pacific/Guam",
    "Pacific/Honolulu",
    "Pacific/Kanton",
    "Pacific/Kiritimati",
    "Pacific/Kosrae",
    "Pacific/Kwajalein",
    "Pacific/Majuro",
    "Pacific/Marquesas",
    "Pacific/Midway",
    "Pacific/Nauru",
    "Pacific/Niue",
    "Pacific/Norfolk",
    "Pacific/Noumea",
    "Pacific/Pago_Pago",
    "Pacific/Palau",
    "Pacific/Pitcairn",
    "Pacific/Pohnpei",
    "Pacific/Port_Moresby",
    "Pacific/Rarotonga",
    "Pacific/Saipan",
    "Pacific/Tahiti",
    "Pacific/Tarawa",
    "Pacific/Tongatapu",
    "Pacific/Wake",
    "Pacific/Wallis",
]

exports.timeRanges = [
    '00:00 AM',
    '01:00 AM',
    '02:00 AM',
    '03:00 AM',
    '04:00 AM',
    '05:00 AM',
    '06:00 AM',
    '07:00 AM',
    '08:00 AM',
    '09:00 AM',
    '10:00 AM',
    '11:00 AM',
    '12:00 PM',
    '00:00 PM',
    '01:00 PM',
    '02:00 PM',
    '03:00 PM',
    '04:00 PM',
    '05:00 PM',
    '06:00 PM',
    '07:00 PM',
    '08:00 PM',
    '09:00 PM',
    '10:00 PM',
    '11:00 PM',
]