import React from 'react';
import Chip from '@material-ui/core/Chip';
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';

const useStyles = makeStyles((theme) => ({
  resize: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2.5em'
    }
  },
  resizeOption: {
    [theme.breakpoints.down('lg')]: {
      fontSize: '2em',
      padding: '1em 0'
    }
  }
}));

const SelectChipComponent = ({data, enums, updateState, initialValues=[]}) => {
  const classes = useStyles();
  const initialValueOptions = initialValues === null ? [] : initialValues;
  const fixedOptions = [];
  const [value, setValue] = React.useState([...initialValueOptions]);
  let enumOptions = [];
  if(true === enums?.hasOwnProperty(data.id)) {
    enumOptions = enums[data.id];
  } 

  if(enums?.length > 0 && false === enums?.hasOwnProperty(data.id))
  {
    enumOptions = enums;
  }

  return <>
      <Autocomplete
      classes={{
        input: classes.resize,
        option: classes.resizeOption,
      }}
          multiple
          id={data.id}
          name={data.id}
          value={value}
          onChange={(event, newValue) => {
            setValue([
              ...fixedOptions,
              ...newValue.filter(
                (option) => fixedOptions.indexOf(option) === -1
              ),
            ]);
            const newEvent = {
              target: {
                name: data.id,
                value: newValue
              }
            }
            updateState(newEvent);
          }}
          options={enumOptions}
          getOptionLabel={(option) => option?.label}
          renderTags={(tagValue, getTagProps) =>
            tagValue.map((option, index) => (
              <Chip
                label={option.label}
                sx={{ fontSize: { md: '2em', lg: '1em' }, padding: '1em 0' }}
                {...getTagProps({ index })}
                disabled={fixedOptions.indexOf(option) !== -1}
              />
            ))
          }

          renderInput={(params) => (
            <TextField
              {...params}
              label={
                <Typography sx={{ fontSize: { md: '2em', lg: '1em' } }}>
                  {data.label}
                </Typography>
              }
              variant="outlined"
              placeholder={
                <Typography sx={{ fontSize: { md: '3em', lg: '1em' } }}>
                  {data.label}
                </Typography>
              }
            />
          )}
        />
  </>
}
export default SelectChipComponent;
