import { Button, Card, CardActionArea, CardActions, CardContent, CardMedia, Stack, Typography } from "@material-ui/core";
import customizedSound from '../../../assets/images/customizedSound.jpg'
import customizedLesson from '../../../assets/images/customizedLessons.jpg'
import { Link } from "react-router-dom";
import { FormattedMessage } from 'react-intl';
const DashboardPage = () => {


  return (
    <>
      <Stack direction="row" spacing={5} sx={{ mt: '7vh' }}>
        <Card sx={{ maxWidth: { md: '100vw', lg: '20vw' } }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={customizedSound}
              alt="Customized Brain Wave Tool"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { md: '2em', lg: '1.25em' } }}>
                <FormattedMessage id="brain-wave-tool" />
              </Typography>
              <Typography variant="body2" color="text.secondary" sx={{ fontSize: { md: '2em', lg: '1.25em' } }}>
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Link to="/pages/customizedContent" style={{ textDecoration: 'none' }} >
              <Button sx={{ fontSize: { md: '2em', lg: '1.25em' } }} color="secondary" variant="outlined" style={{ textDecoration: 'none' }}><FormattedMessage id="go-to-customized-audio" /></Button>
            </Link>
          </CardActions>
        </Card>
        <Card sx={{ maxWidth: { md: '100vw', lg: '20vw' } }}>
          <CardActionArea>
            <CardMedia
              component="img"
              height="200"
              image={customizedLesson}
              alt="Customized Content (Lessons)"
            />
            <CardContent>
              <Typography gutterBottom variant="h5" component="div" sx={{ fontSize: { md: '2em', lg: '1.25em' } }}>
                <FormattedMessage id="customized-content-lessons" />
              </Typography>
            </CardContent>
          </CardActionArea>
          <CardActions>
            <Link to="/pages/customizedLesson/1" style={{ textDecoration: 'none' }}>
              <Button sx={{ fontSize: { md: '2em', lg: '1.25em' } }} color="secondary" variant="outlined" style={{ textDecoration: 'none' }}><FormattedMessage id="go-to-customized-lessons" /></Button>
            </Link>
          </CardActions>
        </Card> 
      </Stack>

    </>
  );
};

export default DashboardPage;
