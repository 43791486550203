import React, {useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionDetails from '@material-ui/core/AccordionDetails';
import Typography from '@material-ui/core/Typography';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import ListItemComponent from './ListItemComponent';
import { AssignmentContext } from '../../contexts/AssignmentContext';

const useStyles = makeStyles((theme) => ({
    formControl: {
      margin: theme.spacing(1),
      width: '90%',
    },
    selectEmpty: {
      marginTop: theme.spacing(2),
    },
    heading: {
      backgroundColor: '#e8e8e8'
    }
  }));

const DetailComponent = ({data, editTemplate}) => {

    const classes = useStyles();
    let formattedResult = [];
    const assignmentContext = useContext(AssignmentContext);
    const assignmentGroupKey = assignmentContext.assignment.groupByKey;

    data.forEach((element, index) => {
      const groupId = element[assignmentGroupKey[0]][assignmentGroupKey[1]];
      if(undefined === formattedResult[groupId]) {
        formattedResult[groupId] = {
              label: element[assignmentGroupKey[0]]['label'],
              data: [],
        }
      }
      const newElement = {...element};
      newElement['index'] = index;
      formattedResult[groupId].data.push(newElement);
    });
    const results = [];
    for (const [key, value] of Object.entries(formattedResult)) {
        console.log(key);
        results.push(value);
    }


    return <>
    {
      results.map((result) => (
        <Accordion>
        <AccordionSummary className={classes.heading}
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1a-content"
          id="panel1a-header"
        >
            <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold' }}><strong>{result.label}</strong></Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography>
          <ListItemComponent data={result.data}/>
          </Typography>
        </AccordionDetails>
      </Accordion>
      ))

    }

    </>

}

export default DetailComponent;
