import React, {useContext} from 'react';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import Chip from '@material-ui/core/Chip';
import { AssignmentContext } from '../../contexts/AssignmentContext';
import DeleteComponent from './DeleteComponent';
import EditComponent from './EditComponent';
import { Typography } from '@material-ui/core';

const NormalTableComponent = ({assignment, data, dependencies}) => {
  const assignmentContext = useContext(AssignmentContext);
  const setDataToTableCell = (type, data) => {
      if ('multiple' ===  type) {
        if('string' === typeof data) 
        {
          return data;
        }

        return data?.map((item) => (
          <Chip label={<Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold', padding: '1em 0' }}>{item.label}</Typography>} style={{ margin: '0.5em' }} />
        ))
      } else if('object' ===  type) {
        return data['label'];
      }else {
          return data;
      }
  }
  const formatAndSetResutls = (result) => {
    result.forEach((element, index) => {
      element['index'] = index;
    })
    assignmentContext.setResults(result);
}
  const deleteItem = (data) => {
      const tempResult = [...assignmentContext.results];
      tempResult.splice(data.index, 1);
      assignmentContext.setResults(tempResult);
  }

  const editItem = (data) => {
    const tempResult = [...assignmentContext.results];
    tempResult[data.index] = data;
    formatAndSetResutls(tempResult);
  }

  return (
    <>

    <Paper style={{  width: "100%", overflow: "auto"}}>
     <Table aria-label="simple table" style={{ minWidth: 700}}>
       <TableHead stickyHeader>
           <TableRow style={{ backgroundColor: 'gray', color: 'white'}}>
               {
                 assignment?.template?.details?.tableHeader.map((headerCell) => (
                   <TableCell style={{ color: 'white' }} >
                     <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold', padding: '0 2em' }}>
                       {headerCell.label}
                     </Typography>
                   </TableCell>
                 ))
               }
              <TableCell style={{ color: 'white', width: '10%' }}>
                <Typography sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold', padding: '0 2em' }}>
                  Actions
                </Typography>
              </TableCell>
            </TableRow>
       </TableHead>

       <TableBody>
       {
         assignmentContext.results.map((value) => {
           let bgColor = '';
            if('Flow state' === assignment?.title)
            {
                let feeling = 'Positive' === value?.feeling?.label;
                let innateSkill = 'Yes' === value?.innateSkill?.label;
                let timePassesFaster = 'Yes' === value?.timePassesFaster?.label;
                let demand =  ('Medium' === value?.demand?.label || 'High' === value?.demand?.label);

                if(feeling && innateSkill && timePassesFaster  && demand) 
                {
                   bgColor = '#ffcdd2';
                }

                if('success' === value?.state?.color) 
                {
                   bgColor = '#a5d6a7';
                }
            }

            if('Dream Validation' === assignment?.title)
            {
                if('error' === value?.state?.color) 
                {
                   bgColor = '#ffcdd2';
                }

                if('success' === value?.state?.color) 
                {
                   bgColor = '#a5d6a7';
                }
            }


             return <> <TableRow style={{backgroundColor: bgColor}}>
              {
                 assignment?.template?.details?.tableHeader?.map((headerCell) => (

                   <TableCell sx={{ fontSize: { md: '2em', lg: '1em' }, fontWeight: 'bold' }}>
                    {
                      setDataToTableCell(headerCell.type, value[headerCell.id])
                    }
                    </TableCell>
                 ))
              }
               <TableCell sx={{ display: 'flex', justifyContent: 'space-around' }}>
                 <EditComponent data={value} editFunc={editItem} initialValues={value} dependencies={dependencies} />
                 <DeleteComponent data={value} deleteFunc={deleteItem} />
              </TableCell>
         </TableRow>
         </>;
       })
       }
       </TableBody>
    </Table>
    </Paper>

    </>
  )
}


export default NormalTableComponent;
