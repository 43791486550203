import React, {useState, useContext} from 'react'
import { makeStyles } from '@material-ui/core/styles';
import Box from '@material-ui/core/Box';

import Dialog from '@material-ui/core/Dialog';
import DialogActions from '@material-ui/core/DialogActions';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import DeleteIcon from '@material-ui/icons/Delete';
import IconButton from '@material-ui/core/IconButton';
import { AssignmentContext } from '../../contexts/AssignmentContext';

const useStyles = makeStyles((theme) => ({
  container: {
      margin: '1em 0',
    },
  btnSuccess: {
    color: 'white'
  },
  formControl: {
    margin: '1em 0'
  },
  button: {
    margin: '0 2em'
  },
  footer: {
    width: '95%',
    margin: '0 auto',
  }
  }));

const DeleteComponent = ({data, deleteFunc}) => {

    const classes = useStyles();
    const [open, setOpen] = useState(false);
    const assignmentContext = useContext(AssignmentContext);
    const handleClickOpen = () => {
      setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleDelete = (event) => {
      event.preventDefault();
      deleteFunc(data);
      handleClose();
    }

    return <>
        <IconButton aria-label="edits" onClick={handleClickOpen} style={{display: true === assignmentContext?.taskCompleted ? 'none': 'flex'}}>
        <DeleteIcon sx={{ fontSize: { md: '2em', lg: '1.25em' } }} />
        </IconButton>
    <Dialog open={open} onClose={handleClose} aria-labelledby="form-dialog-title" fullWidth={true}>
        <DialogTitle><h2>Delete Item [ {assignmentContext.assignment.title} ]</h2></DialogTitle>
            <DialogContent>
              Are you sure, You want to delete this ?
            </DialogContent>
        <DialogActions>
          <Box display="flex" justifyContent="flex-end" className={classes.footer}>
            <Button
              onClick={handleClose}
              size="large"
              variant="outlined"
              color="secondary"
              className={classes.button}
              >
                Cancel
            </Button>
            <Button
                variant="contained"
                color="secondary"
                size="large"
                onClick={handleDelete}
                startIcon={<DeleteIcon />}
            >
                Delete
            </Button>
          </Box>
        </DialogActions>
    </Dialog>
    </>

}

export default DeleteComponent;
